<template>
    <el-dialog
        :title="type==='update'?'修改保险类别':'创建保险类别'"
        custom-class="dialogClass"
        :visible.sync="show"
        width="30%">
        <el-form :hide-required-asterisk="true" :model="formData" :rules="rules"  ref="formRef"
            label-width="auto" class="demo-ruleForm">
            <div class="formNode">
                <el-form-item label="保险编码" prop="insurancecode">
                    <el-input v-model="formData.insurancecode" placeholder="请输入" aria-placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="保险类型" prop="insurancetype">
                    <el-input v-model="formData.insurancetype" placeholder="请输入" aria-placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="职业类别" prop="category">
                    <el-input v-model="formData.category" placeholder="请输入" aria-placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="日保/月保" prop="insurancetime">
                    <el-select v-model="formData.insurancetime" placeholder="请选择">
                        <el-option
                            v-for="item in insurancetimeList"
                            :key="item.name"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保额" prop="insuredamount">
                    <el-input v-model="formData.insuredamount" type="number" min="0" placeholder="请输入" aria-placeholder="请输入">
                        <template slot="suffix">万元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="费用" class="form-suffix-select">
                    <el-form-item prop="insurancemoney">
                        <el-input v-model="formData.insurancemoney" type="number" min="0" placeholder="请输入" aria-placeholder="请输入"> </el-input>
                    </el-form-item>
                    <el-form-item prop="unit">
                       <el-select class="suffix-select" v-model="formData.unit" placeholder="请选择">
                            <el-option label="元/天" :value="0"></el-option>
                            <el-option label="元/月" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form-item>
                
                <el-form-item label="年龄范围" class="age-range">
                    <el-form-item prop="minage">
                        <el-input v-model="formData.minage" type="number" :min="16" placeholder="请输入最小值" aria-placeholder="请输入最小值"></el-input>
                    </el-form-item>-
                    <el-form-item prop="maxage">
                        <el-input v-model="formData.maxage" type="number" :max="65" placeholder="请输入最大值" aria-placeholder="请输入最大值"></el-input>
                    </el-form-item>
                </el-form-item>
            </div>
            <div class="formNode">
                <el-form-item label="支持自动续保" prop="renewflag">
                    <el-radio-group v-model="formData.renewflag">
                        <el-radio :label="0">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onSubmit">提交</el-button>
            <el-button v-if="type === 'create'" type="primary" @click="resetForm">重置</el-button>
            <el-button v-else @click="() => show = false">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
    import { addInsuranceTpye, modifyInsuranceTpye } from  '../../../api/authorityManage'

    export default {
        name: "updateInsurancType",
        data() {
            return {
                type: 'create',
                show: false,
                insurancetimeList: [{
                    name: '日保',
                    value: '日保'
                },{
                    name: '月保',
                    value: '月保'
                }],
                formData: {
                    insurancecode: '',
                    insurancetype: '',
                    category: '',
                    insurancetime: '',
                    insuredamount: '',
                    insurancemoney: '',
                    renewflag: '',
                    unit: 0,
                    minage: '',
                    maxage: ''
                },
                rules: {
                    insurancecode: [
                        { required: true, message: '请输入保险编码', trigger: 'blur' },
                    ],
                    insurancetype: [
                        { required: true, message: '请输入保险类型', trigger: 'change' },
                    ],
                    category: [
                        { required: true, message: '请输入职业类别', trigger: 'change' },
                    ],
                    insurancetime: [
                        { required: true, message: '请选择日保/月保', trigger: 'change' },
                    ],
                    insuredamount: [
                        { required: true, message: '请输入保额', trigger: 'blur' },
                    ],
                    insurancemoney: [
                        { required: true, message: '请输入费用', trigger: 'blur' },
                    ],
                    unit: [
                        { required: true, message: '请选择费用单位', trigger: 'blur' },
                    ],
                    minage: [
                        { validator: this.minagevalidate, trigger: 'blur', },
                    ],
                    maxage: [
                        { validator: this.maxagevalidate, trigger: 'blur', },
                    ],
                    renewflag: [
                        { required: true, message: '请选择是否支持自动续保', trigger: 'change' },
                    ],
                },
            }
        },
        methods: {
            minagevalidate (rule, value, callback) {
                if (!value) {
                    callback(new Error('请输入最小年龄'));
                } else if (/^(0|[1-9]\d*)?$/.test(value) === false) {
                    callback(new Error('请输入有效正整数'));
                } else if (value < 16 || value > 65 || (this.formData.maxage && value > this.formData.maxage)) {
                    callback(new Error('16-65,且应小于等于最大年龄'));
                } else {
                    callback();
                }
            },
            maxagevalidate(rule, value, callback) {
                if (!value) {
                    callback(new Error('请输入最大年龄'));
                } else if (/^(0|[1-9]\d*)?$/.test(value) === false) {
                    callback(new Error('请输入有效正整数'));
                } else if (value < 16 || value > 65 || (this.formData.minage && value < this.formData.minage)) {
                    callback(new Error('16-65,且应大于等于最小年龄'));
                } else {
                    callback();
                }
            },
            onSubmit() {
                this.$refs['formRef'].validate((valid) => {
                    console.log('valid', valid)
                    if (valid) {
                        this.$ELoading();
                        const parms = {
                            insurancecode: this.formData.insurancecode,
                            insurancetype: this.formData.insurancetype,
                            category: this.formData.category,
                            insurancetime: this.formData.insurancetime,
                            insurancemoney: this.formData.insurancemoney,
                            renewflag: this.formData.renewflag,
                            insuredamount: `${this.formData.insuredamount}万`,
                            unit: this.formData.unit,
                            minage: Number(this.formData.minage),
                            maxage: Number(this.formData.maxage)
                        }
                        if (this.type === 'create') {
                            addInsuranceTpye(parms).then(res => {
                                console.log('res======', res)
                                this.$EClose();
                                if (res.code === 1000) {
                                    this.$message.success('添加成功');
                                    this.show = false;
                                    console.log('this.$parent=======', this.$parent)
                                    this.$parent.$refs.vue.getInsuranceTpyeList();
                                }else{
                                    this.$message.warning('添加失败')
                                }
                            })
                        }
                        if (this.type === 'update') {
                            parms.id = this.formData.id;
                            modifyInsuranceTpye(parms).then(res => {
                                console.log('res======', res)
                                this.$EClose();
                                if (res.code === 1000) {
                                    this.$message.success('修改成功');
                                    this.show = false;
                                    this.$parent.getInsuranceTpyeList();
                                }else{
                                    this.$message.warning('修改失败')
                                }
                            })
                        }
                    }
                })
            },
            resetForm() {
                this.$refs['formRef'].resetFields();
            }
        }
    }
</script>
<style lang="scss" scoped>
    ::v-deep .el-checkbox__inner {
        border-radius: 50%;
    }
    
    ::v-deep .el-form-item__content{
        max-width: 300px;
        .el-select {
            width: 100%;
        }
    }
    .age-range {
        ::v-deep .el-form-item__content {
            display: flex;
        }
    }
    .form-suffix-select {
        ::v-deep .el-form-item__content {
            display: flex;
            .el-form-item{
                &:first-child {
                    flex: 1;
                }
                &:last-child {
                    width: 80px;
                }
            }
        }
    }
    ::v-deep .suffix-select {
        width: 80px;
        height: 100%;
        .el-input__inner {
            width: 80px;
        }
        // .el-input.is-disabled .el-input__inner {
        //     background-color: transparent;
        // }
        }
    ::v-deep .dialogClass{
        width: auto !important;
        max-width:50vw;
        border-radius: 5px;
        background: rgba(255, 255, 255, 1);
    }
    ::v-deep .el-dialog__title{
        font-size:15px;
        position: relative;
        padding-left: 15px;
        line-height: 1;
    }
    ::v-deep .el-dialog__title:before{
        width: 5px;
        height: 15px;
        border-radius: 4px;
        background: rgba(31, 120, 255, 1);
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    ::v-deep .el-dialog__body{
        padding: 30px 40px;
        padding-bottom: 10px;
        max-height: calc(100vh - 25vh - 200px);
        overflow-y: auto;
    }
    .dialog-footer{
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .dialog-footer>button:nth-of-type(1){
        width: 40%;
        max-width: 140px;
        max-height: 61px;
        border-radius: 5px;
        background: rgba(31, 120, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
    }
    .dialog-footer>button:hover{
        opacity: .9;
        transition: all linear .2s;
    }
    .dialog-footer>button:nth-of-type(2){
        width: 40%;
        max-width: 140px;
        max-height: 61px;
        border-radius: 5px;
        background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid rgba(31, 120, 255, 1);
        color: rgba(31, 120, 255, 1);
        font-size: 14px;
    }
    .formNode{
        margin-top: 5px;
        box-sizing: border-box;
    }
</style>