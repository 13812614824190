<template>
  <div class="page">
    <div class="header">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        text-color="black"
      >
        <div class="returnClass" @click="goBack">
          <i class="el-icon-caret-left"></i>
          <span>返回</span>
        </div>

        <el-menu-item :index="activeIndex">任务详情</el-menu-item>
      </el-menu>
    </div>
    <div class="main">
      <component :is="vue" ref="vue"></component>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "default",
      vue: "default",
      parent: {},
    };
  },
  components: {
    default: () => import("./deatil/sonDetail"),
  },

  created() {},
  methods: {
    goBack() {
      this.$router.push("/Worktable/taskManage/taskAudit");
    },
    handleSelect() {
      console.log("===B===");
    },
  },
};
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  .header {
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    .el-menu-demo {
      position: relative;
    }
    .el-menu-Btn {
      max-width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      right: 10px;
    }
  }
  .main {
    width: 100%;
    background-color: #fff;
    height: calc(100% - 57px);
  }
  .el-menu {
    display: flex;
    align-items: flex-end;
  }
  .el-menu--horizontal > .el-menu-item {
    height: 45px;
  }
  .el-menu.el-menu--horizontal {
    padding-left: 50px !important;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 2px solid rgba(42, 85, 229, 1);
  }
  .el-dropdown-menu__item,
  .el-menu-item {
    padding: 0;
    margin: 0 20px !important;
  }
}
.returnClass {
  cursor: pointer;
  z-index: 999;
  position: absolute;
  bottom: 16px;
  left: 10px;
  color: black;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 700;
  img {
    margin-right: 5px;
  }
}
</style>
