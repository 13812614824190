<template>
  <div class="page">
    <div class="header">
      <el-menu
        :default-active="vue"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        text-color="black"
      >
        <el-menu-item :index="activeIndex1">全部</el-menu-item>
        <el-menu-item :index="activeIndex2">待审核</el-menu-item>
        <el-menu-item :index="activeIndex3">已审核</el-menu-item>
      </el-menu>
      <div class="otherDiv">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <el-form-item>
            <el-input
              v-model="searchForm.taskname"
              autocomplete="off"
              placeholder="请输入任务名称进行搜索"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="main">
      <end ref="vue"></end>
    </div>
  </div>
</template>

<script>
import { getCompanyList } from "../../../api/workbenche";

export default {
  name: "account",
  data() {
    return {
      activeIndex1: "all",
      activeIndex2: "wait",
      activeIndex3: "end",
      vue: "all",
      companyList: [],
      searchForm: {
        taskname: "",
      },
    };
  },
  components: {
    end: () => import("./taskAudit/default"),
  },
  created() {},
  methods: {
    onSearch() {
      this.$ELoading();
      console.log("this.form", this.searchForm);
      this.$refs["vue"].listReq.data.taskname = this.searchForm.taskname;
      this.$refs["vue"].getList().then(() => {
        this.$EClose();
      });
    },
    //添加成功调取列表
    addGetList(v) {
      this.$ELoading();
      getCompanyList({
        data: {
          insurancestatus: "3",
        },
      }).then((res) => {
        console.log("res", res);
        this.$refs["vue"].listReq.data.createtime = this.searchForm.date;
        if (res?.data?.list?.length > 0) {
          this.companyList = res?.data?.list || [];
          this.searchForm.companyid = res?.data?.list?.[0].id;
          this.searchForm.selectedCompany = res?.data?.list?.[0];
          this.$refs["vue"].listReq.userid = res?.data?.list?.[0].id;
          this.$refs["vue"].getList().then(() => {
            this.$EClose();
          });
        } else {
          this.$EClose();
        }
      });
    },
    handleSelect(key, keyPath) {
      this.vue = key;
      this.$refs["vue"].listReq.page = 1;
      if (key === "all") {
        this.$refs["vue"].listReq.data.status = [0, 3, 4, 6];
      } else if (key === "wait") {
        this.$refs["vue"].listReq.data.status = [3, 6];
      } else if (key === "end") {
        this.$refs["vue"].listReq.data.status = [0, 4];
      }
      this.$ELoading();
      this.$refs["vue"].getList().then(() => {
        this.$EClose();
      });
    },
  },
};
</script>
<style scoped>
.page {
  width: 100%;
  height: 100%;
}

.el-form--inline .el-form-item {
  margin-bottom: 0;
}

.header {
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-bottom: solid 1px #e6e6e6;
}

.el-menu {
  display: flex;
  align-items: flex-end;
}

.el-menu--horizontal > .el-menu-item {
  height: 45px;
}

.el-menu.el-menu--horizontal {
  padding-left: 86px !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid rgba(42, 85, 229, 1);
}

.main {
  width: 100%;
  height: calc(100% - 57px);
}

.el-dropdown-menu__item,
.el-menu-item {
  padding: 0;
  margin: 0 20px !important;
}

.otherDiv {
  padding: 0 20px;
  box-sizing: border-box;
}

.otherDiv button {
  width: 123px;
  height: 39px;
  border-radius: 4px;
  background: rgba(73, 119, 252, 1);
  color: rgba(255, 255, 255, 1);
}

.otherDiv button:hover {
  opacity: 0.9;
}
</style>
