<template>
    <div class="page">
        <div class="header">
            <el-menu
                    :default-active="list[0].index"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect"
                    text-color="black"
            >
                <el-menu-item :index="item.index" v-for="(item,i) in list" :key="i">{{item.name}}</el-menu-item>
                <div class="el-menu-Btn">
                    <template>
                        <div class="searchItem">
                            <button class="clickBtn" @click="addOpen">新建模板</button>
                        </div>
<!--                        <div class="searchItem">-->
<!--                            <el-select ref="company" v-model="search.type"  placeholder="请选择合同类型" size="small" clearable>-->
<!--                                <el-option :label="li.label" :value="li.id" v-for="(li,i) in searchBody.typeList" :key="i"></el-option>-->
<!--                            </el-select>-->
<!--                        </div>-->
<!--                        <div class="searchItem">-->
<!--                            <el-select ref="company" v-model="search.projectid"  placeholder="请选择关联项目" size="small" clearable>-->
<!--                                <el-option :label="li.projectname" :value="li.id" v-for="(li,i) in searchBody.projectList" :key="i"></el-option>-->
<!--                            </el-select>-->
<!--                        </div>-->
                        <div class="searchItem">
                            <button class="clickBtn" @click="clickSearchBtn">刷新列表</button>
                        </div>
                    </template>

                </div>
            </el-menu>
            <div class="line"></div>
        </div>
        <div class="main">
            <component :is="vue" ref="vue"></component>
        </div>
      <created ref="createdRef"></created>
    </div>
</template>

<script>
    export default {
        name: "contract",
        data() {
            return {
                vue:'default',
                list:[
                    {
                        name:'模板管理',
                        index:'default',
                    },
                ],
                search:{docno:'',name:'',projectid:'',type:''},
                searchBody:{
                    typeList:[{label:'劳务合同',id:'1'},{label:'劳动合同',id:'2'},{label:'用工协议',id:'3'},{label:'离职合同',id:'4'},{label:'服务协议',id:'5'}],
                    mainCompanyList:[],
                    projectList:[]
                },
            };

        },
        components:{
          default:()=>import('./contract/default'),
          created:()=>import('./contract/component/created'),
        },
        beforeDestroy() {
            document.removeEventListener("visibilitychange",this.$refs['vue'].documentEvent)
        },
        methods: {
            handleSelect(key) {
              this.vue=key;
              this.$router.push(this.$route.path)
            },
            //打开创建
            addOpen(){
              this.$refs['createdRef'].show=true
                // this.$refs['vue'].openWindow()
            },
            //查询
            clickSearchBtn(){
              if(this.$refs['vue']){
                this.$ELoading()//开启遮罩
                for (const key in this.$refs['vue'].tableReq.data) {//搜索请求体赋值
                  this.$refs['vue'].tableReq.data[key]=this.search[key]
                }
                this.$refs['vue'].tableReq.page?this.$refs['vue'].tableReq.page=1:''//搜索时页数重置
                this.$refs['vue'].$refs['tableCmp'].resetNo()
                this.$refs['vue'].searchEndBtn()//调用搜索方法
              }
            },
        },
    };
</script>
<style scoped lang="scss">
    .page {
        width: 100%;
        height: 100%;
        .header {
            width: 100%;
            height: 56px;
            box-sizing: border-box;
            .el-menu-demo{
                position: relative;
            }
            .el-menu-Btn{
                max-width: 70%;
                height: 100%;
                display: flex;
                align-items: center;
                position: absolute;
                right: 10px;
                .searchItem{
                    margin: 0 5px;
                }
                .clickBtn{
                    white-space: nowrap;
                    padding: 0 25px;
                    height: 30px;
                    opacity: 1;
                    border-radius: 4px;
                    background: rgba(73, 119, 252, 1);
                    border: none;
                    color: rgba(255, 255, 255, 1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    line-height: 1px;
                }
                .clickBtn:hover{
                    background: rgb(95, 133, 243);
                }
            }
        }
        .main {
            width: 100%;
            height: calc(100% - 57px);
        }
        .el-menu {
            display: flex;
            align-items: flex-end;
        }
        .el-menu--horizontal > .el-menu-item {
            height: 45px;
        }
        .el-menu.el-menu--horizontal {
            padding-left: 50px !important;
        }
        .el-menu--horizontal > .el-menu-item.is-active {
            border-bottom: 2px solid rgba(42, 85, 229, 1);
        }
        .el-dropdown-menu__item, .el-menu-item{
            padding: 0;
            margin: 0 20px !important;
        }
    }
</style>
