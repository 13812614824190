<template>
    <div id="sonPage">
        <alterCmp ref="alterCmp" @getTableData="getTableData"></alterCmp>
        <div class="componentBox">
            <tableCmp ref="tableCmp" :tableData="tableData"  @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
                <template #[tableData.cols[3].prop]="{rowData:rowData}">
                    {{rowData.data1.type|typeFilter}}
                </template>
                <template #[tableData.cols[4].prop]="{rowData:rowData}">
                    {{$iApi.moment(rowData.data1.createtime,'YYYY-MM-DD HH:mm:ss')}}
                </template>
                <template #operate="{rowData:rowData}">
                    <el-button type="text" size="small" @click="alterClick(rowData)">完善</el-button>
                    <el-button type="text" size="small" @click="deleteClick(rowData)">删除</el-button>
                </template>
            </tableCmp>
        </div>
    </div>
</template>

<script>
    import {getETList,getCustomField,getAddWebUrl,delETContract} from '@/api/workbenche.js'
    let that  = null
    export default {
        name: "start",
        components:{
            tableCmp:()=>import('@/components/myTable/table'),
            alterCmp:()=>import('./component/etAlterDialog')
        },
        data(){
            return {
                tableData: {//表格组件数据
                    currentPage:0,
                    data:null,
                    cols:[
                        {label:'模板编号',prop:'data1.contractno'},
                        {label:'模板名称',prop:'data1.name'},
                        {label:'所属服务商',prop:'servicecompanyname'},
                        {label:'合同类型',prop:'data1.type'},
                        {label:'创建时间',prop:'data1.createtime'},
                    ],
                },
                webUrl:'',//web链接
                list:{
                    projectList:[],//项目清单
                    mainCompanyList:'',//主体企业列表
                },
                tableReq:{
                    "page": 1,
                    "index": 20,
                    "data": {

                    }
                },
            }
        },
        created() {
            this.$ELoading()
            that=this//存this filters使用
          this.getTableData()
        },
        beforeDestroy() {
            document.removeEventListener("visibilitychange",this.documentEvent)
        },
        destroyed() {
            document.removeEventListener("visibilitychange",this.documentEvent)
        },
        filters:{
            //根据项目id返回项目名称
            projectFilter:(data)=>{
                if(!(that.list&&that.list.projectList&&that.list.projectList.length))return '--'
                return (that.list.projectList.filter(item=>item.id==data)).length?(that.list.projectList.filter(item=>item.id==data))[0].projectname:'--'
            },
            //根据类型type返回类型名称
            typeFilter:(data)=>{
                return (that.$parent.searchBody.typeList.filter(item=>item.id==data)).length?(that.$parent.searchBody.typeList.filter(item=>item.id==data))[0].label:'--'
            }
        },
        methods: {
            //搜索
            searchEndBtn() {
                this.getTableData()
            },
            //条数change事件
            handleSizeChange(val) {
                this.$ELoading()
                this.tableReq.index=val
                this.tableReq.page=1//页数重置
                this.getTableData()
            },
            //页数change事件
            handleCurrentChange(val) {
                this.$ELoading()
                this.tableReq.page=val
                this.getTableData()
            },
            //完善
            alterClick(row) {
                this.$ELoading()
                getCustomField(row?.data1.contractno).then(res => {
                    this.$refs['alterCmp'].dialogVisible = true
                    this.$refs['alterCmp'].fieldList=[]
                    this.$refs['alterCmp'].forReq={}
                    this.$EClose()
                    //动态生成表单input组件及配置
                    if (res.code == 1000) {
                        this.$refs['alterCmp'].fieldList = res.data
                        this.$refs['alterCmp'].forReq = this.$refs['alterCmp'].fieldList.map((item, i) => {
                            this.$refs['alterCmp'].$set(this.$refs['alterCmp'].alterReq, `value${i}`, '')
                            this.$refs['alterCmp'].$set(this.$refs['alterCmp'].rules, `value${i}`, [{
                                required: true,
                                message: '不能为空',
                                trigger: 'blur'
                            },])
                            return {name: item.name, value: ''}
                        })
                        Object.keys(row).forEach(item => {
                            Object.keys(this.$refs['alterCmp'].alterReq).forEach(XItem => {
                                if (item == XItem) {
                                    this.$set(this.$refs['alterCmp'].alterReq, XItem, row[item])
                                }
                            })
                        })
                        if (!this.$refs['alterCmp'].alterReq.type) {
                            this.$refs['alterCmp'].alterReq.type = "5"
                        }
                        if (!this.$refs['alterCmp'].alterReq.projectid) {
                            this.$refs['alterCmp'].alterReq.projectid = ""
                        } else {
                            this.$refs['alterCmp'].alterReq.projectid = parseInt(this.$refs['alterCmp'].alterReq.projectid)
                        }
                        this.$refs['alterCmp'].fieldList.forEach((item, i) => {
                            this.$refs['alterCmp'].alterReq[`value${i}`] = ''
                        })
                        if (row.cloumnsjson && row.cloumnsjson.length && JSON.parse(row.cloumnsjson).length) {
                            JSON.parse(row.cloumnsjson).forEach((item, i) => {
                                Object.keys(this.$refs['alterCmp'].alterReq).forEach(li => {
                                    if (li.includes('value') && (li.replace('value', '') == i)) {
                                        this.$set(this.$refs['alterCmp'].alterReq, `value${i}`, item.value)
                                    }
                                })
                            })
                        }
                        this.$nextTick(()=>{
                            this.$refs['alterCmp'].$refs['alterForm'].clearValidate()
                            this.$refs['alterCmp'].alterReq.docno=row?.data1?.contractno//合同编号
                            this.$refs['alterCmp'].alterReq.id=row?.data1?.id//合同id
                            this.$refs['alterCmp'].alterReq.name=row?.data1?.name//合同模板名称
                        })
                    }
                })
            },
            //删除
            deleteClick(row) {
                this.$confirm('是否确定删除该模板?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$ELoading()
                    delETContract(row?.data1?.id).then(res => {
                        if (res.code == 1000) {
                            this.$message.success('删除成功')
                            this.getTableData()
                        } else {
                            this.$EClose()
                            return this.$message.error(res.message||'删除失败')
                        }
                    })

                }).catch(() => {})
            },
            //页面前台监听
            documentEvent() {
                if(!document.hidden){
                    //处于当前页面
                    this.$ELoading();
                    this.getAddWebUrl().then(() => {
                        this.$EClose()
                    })
                }
            },
            //打开外部web链接
            openWindow() {
                window.open(this.webUrl)
            },
            //获取添加模板功能的web链接
            getAddWebUrl() {
                return new Promise((resolve, reject) => {
                    getAddWebUrl().then(res => {
                        resolve()
                        if (res&&res.code == 1000) {
                            this.webUrl = res.message
                        }
                    })
                })
            },
            //获取列表数据
            getTableData() {
                this.$ELoading()
                return new Promise(resolve => {
                    getETList(this.$req(this.tableReq)).then(res => {
                        if(res){
                          resolve()
                          this.$EClose()
                            if (res.code == 1000) {
                                this.tableData.data = res.data
                            }
                        }
                    })
                })
            },
            //获取项目清单
            getProjectListShare(){
               return new Promise(resolve => {
                   // getProjectListShare().then(res=>{
                   //     resolve()
                   //     if(res.code==1000){
                   //         this.$parent.searchBody.projectList=res.data.paasuserProjectList
                   //         this.$refs['alterCmp'].list.projectList=res.data.paasuserProjectList
                   //         this.list.projectList=res.data.paasuserProjectList
                   //     }
                   // })
                 resolve()
               })
            }
        }
    }
</script>

<style scoped lang="scss">
    #sonPage{
        width: 100%;
        height: 100%;
        padding-top:15px;
        padding-left:15px;
        padding-right:15px;
        box-sizing: border-box;
        .componentBox{
            width: 100%;
            height: 100%;
            background: white;
        }
    }
</style>
