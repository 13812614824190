<template>
  <div>
    <el-dialog
        title="创建合同模板"
        custom-class="dialogClass"
        :visible.sync="show"
        width="30%">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
        <el-form-item label="服务商" prop="serviceid">
          <el-select ref="company" v-model="form.serviceid" placeholder="请选择服务商" size="small" clearable style="width: 100%"
                     @visible-change="visibleChange">
            <el-option :label="li.data1.name" :value="li.data1.id" v-for="(li,i) in serviceList" :key="i"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="submitBtn">下一步</el-button>
                <el-button type="primary" @click="resetForm">重置</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {addVipContract, getVipAccList, addETContract} from "@/api/workbenche";
import rules from "@/util/js/formRules";

export default {
  data() {
    return {
      show: false,
      form: {
        serviceid: '',
      },
      serviceList: [],
      rules: {
        serviceid: rules(),
      },
    };
  },
  methods: {
    async visibleChange(flag) {
      if (flag) {
        this.$ELoading()
        const req = {
          data: {serviceauth: 1},
          index: 500,
          page: 1
        }
        const res = await getVipAccList(req)
        this.$EClose()
        if (res && res.code == 1000) {
          this.serviceList = res.data.list
        }
      }
    },
    submitBtn() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.$ELoading()
          addETContract(this.$req(this.form)).then(res => {
            if (res) {
              this.$EClose()
              if (res.code == 1000) {
                window.open(res.message)
                this.show = false
              } else {
                this.$message.error(res.message || '操作失败')
              }
            }
          })
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs['ruleForm'].resetFields()
    },
  }
};
</script>

<style scoped>
::v-deep .dialogClass {
  max-width: 753px;
  min-width: 500px;
  max-height: 451px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 1);
}

::v-deep .el-dialog__title {
  font-size: 15px;
  position: relative;
  padding-left: 15px;
  line-height: 1;
}

::v-deep .el-dialog__body {
  padding: 30px 40px;
  padding-bottom: 10px;
}

::v-deep .el-dialog__title:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(31, 120, 255, 1);
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dialog-footer {
  display: flex;
  align-content: center;
  justify-content: center;
}

.dialog-footer > button:nth-of-type(1) {
  width: 40%;
  max-width: 140px;
  max-height: 61px;
  border-radius: 5px;
  background: rgba(31, 120, 255, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
}

.dialog-footer > button:hover {
  opacity: .9;
  transition: all linear .2s;
}

.dialog-footer > button:nth-of-type(2) {
  width: 40%;
  max-width: 140px;
  max-height: 61px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(31, 120, 255, 1);
  color: rgba(31, 120, 255, 1);
  font-size: 14px;
}
</style>
