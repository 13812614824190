<template>
  <div>
    <el-dialog
        :title="type!==0?'重新签约':'合作签约'"
        custom-class="dialogClass"
        :visible.sync="show"
        width="30%">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
        <el-form-item label="合同选择" prop="tempid">
          <el-select v-model="form.tempid" placeholder="请选择合同" size="small" filterable
                     @visible-change="visibleChange" style="width: 300px">
            <el-option
                v-for="item in contractList"
                :key="item.data1?.id"
                :label="item.data1?.name"
                :value="item.data1?.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签约截至时间" prop="deadline">
          <el-date-picker
              style="width: 300px"
              size="small"
              filterable
              v-model="form.deadline"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择签约截至时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="submitBtn">发起签约</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {sendEuSign, getETList} from "@/api/workbenche";
import rules from "@/util/js/formRules";

export default {
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show: false,
      form: {
        userid: '',
        serviceid: '',
        tempid: '',
        deadline: '',
      },
      rules: {
        "tempid": rules(),
        "deadline": rules(),
      },
      contractList:[],//合同模板列表
    };
  },
  methods: {
    //下拉请求合同模板
    async visibleChange(flag) {
      if (flag) {
        this.$ELoading()
        const res = await getETList(this.$req({data:{}}))
        this.$EClose()
        if (res?.code == 1000) {
          this.contractList = res.data?.list||[]
          console.log(this.contractList)
        }
      }
    },
    //提交表单
    submitBtn() {
      console.log(this.form)
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.$ELoading()
          sendEuSign(this.$req(this.form)).then(res => {
            if (res) {
              if (res.code == 1000) {
                this.$parent.getList().then(()=>{
                  this.$EClose()
                })
                this.$message.success('操作成功')
                this.show = false
                this.resetForm()
              } else {
                this.$message.error( res.message || '操作失败')
                this.$EClose()
              }
            }
          })
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs['ruleForm'].resetFields()
    },
  }
};
</script>

<style scoped>
::v-deep .el-input__inner {
  max-width: 300px;
}

::v-deep .dialogClass {
  width: auto !important;
  max-width: 600px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
}

::v-deep .el-dialog__title {
  font-size: 15px;
  position: relative;
  padding-left: 15px;
  line-height: 1;
}

::v-deep .el-dialog__title:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(31, 120, 255, 1);
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

::v-deep .el-dialog__body {
  padding: 30px 40px;
  padding-bottom: 10px;
  max-height: calc(100vh - 25vh - 200px);
  overflow-y: auto;
}

.dialog-footer {
  display: flex;
  align-content: center;
  justify-content: center;
}

.dialog-footer > button:nth-of-type(1) {
  width: 40%;
  max-width: 140px;
  max-height: 61px;
  border-radius: 5px;
  background: rgba(31, 120, 255, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
}

.dialog-footer > button:hover {
  opacity: .9;
  transition: all linear .2s;
}

.dialog-footer > button:nth-of-type(2) {
  width: 40%;
  max-width: 140px;
  max-height: 61px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(31, 120, 255, 1);
  color: rgba(31, 120, 255, 1);
  font-size: 14px;
}

.formNode {
  margin-top: 5px;
  box-sizing: border-box;
}

.formNode_title {
  color: rgba(0, 0, 0, 1);
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
}

.formNode_title:before {
  width: 5px;
  height: 15px;
  border-radius: 4px;
  background: rgba(31, 120, 255, 1);
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.clickProtocol {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.clickProtocol:hover {
  cursor: pointer;
  opacity: .8;
  text-decoration: underline;
  transition: all linear .1s;
}

::v-deep .mdyErr .el-form-item__error {
  margin-top: 15px;
}
</style>
