<template>
  <div class="pageMain">
    <div class="tableMain">
      <ETable ref="ETable" :tableData="tableData"  @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
        <template #phone="{rowData:rowData}">
          {{rowData.data2&&rowData.data2.phone?rowData.data2.phone:'--'}}
        </template>
        <template #name="{rowData:rowData}">
          {{rowData.data2&&rowData.data2.name?rowData.data2.name:'--'}}
        </template>
        <template #userphone="{rowData:rowData}">
          {{rowData.data2&&rowData.data2.userphone?rowData.data2.userphone:'--'}}
        </template>
        <template #levelname="{rowData:rowData}">
          {{rowData.data2&&rowData.data2.levelname?rowData.data2.levelname:'--'}}
        </template>
        <template #status="{rowData:rowData}">
          <div v-if="rowData.data1&&rowData.data1.status==1" style="color: #43CF7C">启用中</div>
          <div v-else style="color:#FF5733;">停用中</div>
        </template>
        <template #data3.status="{rowData:rowData}">
          <div v-if="rowData?.data3?.status==='0'" style="color: #FF8D1A">待签署</div>
          <div v-else-if="rowData?.data3?.status==='1'" style="color: #43CF7C">已签署</div>
          <div v-else-if="rowData?.data3?.status==='2'" style="color: #FF5733">已作废</div>
          <div v-else-if="rowData?.data3?.status==='3'" style="color: #43CF7C">乙方已签署</div>
          <div v-else-if="rowData?.data3?.status==='4'" style="color: #43CF7C">甲方已签署</div>
<!--          <div v-else-if="rowData?.data3?.status==='5'" style="color: #43CF7C">已签署</div>-->
<!--          <div v-else-if="rowData?.data3?.status==='6'" style="color: #43CF7C">已签署</div>-->
          <div v-else style="color: #A6A6A6">未签约</div>
        </template>
        <template #operate="{rowData:rowData}">
          <el-button type="text" size="small" @click="detail(rowData)">详情</el-button>
          <template>
            <el-button type="text" size="small" v-if="rowData.data1&&rowData.data1.status==0" @click="alterStats(rowData,1)">启用</el-button>
            <el-button type="text" size="small" v-else @click="alterStats(rowData,0)">停用</el-button>
          </template>
          <template>
            <el-button type="text" size="small" @click="sendSign(rowData,0)" v-if="!rowData.data3">发起签约</el-button>
            <el-button type="text" size="small" @click="sendSign(rowData,0)" v-else-if="rowData.data3.status==2">重新签约</el-button>
          </template>

        </template>
      </ETable>
    </div>
    <addSvs ref="addSvs" :detail="true"></addSvs>
    <sign ref="sign0Ref"></sign>
    <sign ref="sign1Ref" :type="1"></sign>
  </div>
</template>

<script>
  import {getEUUserList, alterEUUserLi, alterVipAccEd} from "@/api/workbenche";
  export default {
    name: "end",
    components:{
      addSvs:()=>import('./component/addSvs'),
      sign:()=>import('./component/sign')
    },
    props:['serviceItem'],
    data() {
      return {
        //表格列表请求体
        listReq:{
          index:20,
          page:1,
          data:{
            serviceid:'',
          }
        },
        tableData: {
          currentPage:0,
          data: [],
          cols:[
            {label:'用户名',prop:'phone'},
            {label:'姓名',prop:'name'},
            {label:'手机号',prop:'phone'},
            {label:'会员级别',prop:'levelname'},
            {label:'状态',prop:'status'},
            {label:'签约状态',prop:'data3.status'},
          ],
        },
      };
    },
    created() {
      this.listReq.data.serviceid=this.serviceItem?.data1?.id
      this.$ELoading()
      this.getList().then(()=>{
        this.$EClose()
      })
    },
    methods:{
      //获取列表
      getList(){
        return new Promise(resolve => {
          getEUUserList(this.$req(this.listReq)).then(res=>{
            if(res){
              resolve()
              if(res?.code==1000){
                this.tableData.data=res.data
              }else{
                this.$message.error(res.message||'操作失败')
              }
            }
          })
        })
      },
      detail(item){
        this.$refs['addSvs'].show=true
        this.$refs['addSvs'].form.data.username= item.data2.phone
        this.$refs['addSvs'].form.data.protocolurl= item.data1.protocolurl
        this.$refs['addSvs'].form.data.submchid = item.data1.submchid||'----'
        this.$refs['addSvs'].form.data.servicetax = item.data1.servicetax?Number(item.data1.servicetax).toFixed(2):'----'
      },
      //停用启用
      alterStats(item,status){
        if(!status){
          this.$EConfirm('停用后，商家无法选择到该服务商，无法使用服务商提供的服务，是否继续？','停用商家','warning').then(()=>{
            this.$ELoading()
            alterEUUserLi(item?.data1?.id,status).then(res=>{
              if(res.code){
                if(res.code==1000){
                  this.getList().then(()=>{
                    this.$EClose()
                  })
                  this.$message.success('操作成功')
                }else{
                  this.$EClose()
                  this.$message.error(res.message?res.message:'操作失败')
                }
              }
            })
          }).catch(()=>{})
        }else{
          this.$ELoading()
          alterEUUserLi(item?.data1?.id,status).then(res=>{
            if(res.code){
              if(res.code==1000){
                this.getList().then(()=>{
                  this.$EClose()
                })
                this.$message.success('操作成功')
              }else{
                this.$EClose()
                this.$message.error(res.message?res.message:'操作失败')
              }
            }
          })
        }
      },
      //签约
      sendSign(item){
        if(!item.data3){
          this.$refs['sign0Ref'].show=true
          this.$refs['sign0Ref'].form.userid = item.data1.userid
          this.$refs['sign0Ref'].form.serviceid = item.data1.serviceid
        }else if(item.data3?.status==2){
          this.$refs['sign1Ref'].show=true
          this.$refs['sign1Ref'].form.userid = item.data1.userid
          this.$refs['sign1Ref'].form.serviceid = item.data1.serviceid
        }
      },
      handleSizeChange(val) {
        this.listReq.index=val
        this.listReq.page=1
        this.$iApi.paging(this.getList).then(()=>{
          this.$EClose()
        })
      },
      handleCurrentChange(val) {
        this.listReq.page=val
        this.$iApi.paging(this.getList).then(()=>{
          this.$EClose()
        })
      },
    },
  };
</script>

<style scoped>
  .pageMain {
    width: 100%;
    height: 100%;
    padding: 16px 19px 0 18px;
    box-sizing: border-box;
  }
  .tableMain{
    width: 100%;
    height: 100%;
  }
</style>
