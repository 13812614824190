<template>
  <div>
    <el-dialog
        :title="type==='edit'?'修改账号':type==='detail'?'账号详情':'创建账号'"
        custom-class="dialogClass"
        :visible.sync="show"
        width="30%">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
        <el-form-item label="头像" :prop="type!='detail'?'data1.imagehead':''">
          <EUpload ref="headerUpload" :style="{pointerEvents: type=='detail'?'none':'auto'}"></EUpload>
        </el-form-item>
        <el-form-item label="服务商公司名称" :prop="type!='detail'?'data1.companyname':''">
          <el-input v-model="form.data1.companyname" size="small" placeholder="请输入公司名称" :readonly="type=='detail'"
                    maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="账号联系人" :prop="type!='detail'?'data1.name':''">
          <el-input v-model="form.data1.name" size="small" placeholder="请输入账号联系人" :readonly="type=='detail'"
                    maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" :prop="type!='detail'?'data1.cardno':''">
          <el-input v-model="form.data1.cardno" size="small" placeholder="请输入身份证号" :readonly="type=='detail'"
                    maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="公司地址" :prop="type!='detail'?'data1.registeraddress':''">
          <el-input v-model="form.data1.registeraddress" size="small" placeholder="请输入公司地址" :readonly="type=='detail'"
                    maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" :prop="type!='detail'?'data1.userphone':''">
          <el-input v-model="form.data1.userphone" size="small" placeholder="请输入联系电话" :readonly="type=='detail'"
                    maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="登录账号" :prop="type!='detail'?'data1.phone':''">
          <el-input v-model="form.data1.phone" size="small" placeholder="请输入登录账号" :readonly="type=='detail'"
                    maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="所在城市" :prop="type!='detail'?'data1.city':''">
          <el-input v-model="form.data1.city" size="small" placeholder="" maxlength="30" readonly
                    v-if="type=='detail'"></el-input>
          <el-cascader
              v-else
              size="small"
              ref="ref_address"
              filterable
              v-model="form.data1.city"
              :options="baseList.ssq"
              :props="{ expandTrigger: 'hover',value:'adcode',label:'title' }"
              @change="cityChange"
              placeholder="请选择所在城市"
              separator="/"
              clearable></el-cascader>
        </el-form-item>
        <el-form-item label="上传营业执照" :prop="type!='detail'?'data2.cardurl':''">
          <EUpload ref="permitUpload" width="205px" height="116px"
                   :style="{pointerEvents: type=='detail'?'none':'auto'}"></EUpload>
        </el-form-item>
        <el-form-item label="上传身份证" :required="type!='detail'?true:false" :show-message="false">
          <div style="display: flex;">
            <el-form-item label="" :prop="type!='detail'?'data1.cardimg1':''">
              <EUpload ref="cardFrontUpload" width="205px" height="116px" name="点击上传身份证正面"
                       :style="{pointerEvents: type=='detail'?'none':'auto'}"></EUpload>
            </el-form-item>
            <div style="padding: 10px"></div>
            <el-form-item label="" :prop="type!='detail'?'data1.cardimg2':''">
              <EUpload ref="cardBackUpload" width="205px" height="116px" name="点击上传身份证反面"
                       :style="{pointerEvents: type=='detail'?'none':'auto'}"></EUpload>
            </el-form-item>
          </div>
        </el-form-item>
        <div class="formNode" v-if="type!=='edit'">
          <div class="formNode_title">合同余量</div>
          <el-form-item label="赠送合同量" :prop="type!='detail'?'data1.contracttotal':''">
            <div style="width:100%;display:flex;align-items:center">
              <div style="width: 30%">
                <el-input v-model="form.data1.contracttotal" size="small" placeholder="" :readonly="type=='detail'"
                          maxlength="30"></el-input>
              </div>
              <div style="margin-left: 5px">份</div>
            </div>
          </el-form-item>
          <el-form-item label="合同单价" :prop="type!='detail'?'data1.contractunit':''">
            <div style="width:100%;display:flex;align-items:center">
              <div style="width: 30%">
                <el-input v-model="form.data1.contractunit" size="small" placeholder="" :readonly="type=='detail'"
                          maxlength="30"></el-input>
              </div>
              <div style="margin-left: 5px">元/份</div>
            </div>
          </el-form-item>
        </div>
        <div class="formNode">
          <div class="formNode_title">银行信息</div>
          <el-form-item label="平安银行账户" :prop="type!='detail'?'data3.orangeaccount':''">
            <el-input v-model="form.data3.orangeaccount" size="small" :placeholder="type!='detail'?'请输入银行账号':''" :readonly="type=='detail'"
                      maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="平安银行账户名" :prop="type!='detail'?'data3.orangeaccountname':''">
            <el-input v-model="form.data3.orangeaccountname" size="small" :placeholder="type!='detail'?'请输入银行账户名':''"
                      :readonly="type=='detail'" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="开户行名称" :prop="type!='detail'?'data3.orangeaccountbank':''">
            <el-input v-model="form.data3.orangeaccountbank" size="small" :placeholder="type!='detail'?'请输入开户行名称':''"
                      :readonly="type=='detail'" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="银行企业编号" :prop="type!='detail'?'data3.orangecomanycode':''">
            <el-input v-model="form.data3.orangecomanycode" size="small" :placeholder="type!='detail'?'请输入银行企业编号':''"
                      :readonly="type=='detail'" maxlength="30"></el-input>
          </el-form-item>
        </div>
        <div class="formNode">
          <div class="formNode_title">后台权限</div>
          <div class="box_title_node">服务商功能权限</div>
          <el-form-item label="" class="tree_checkbox" prop="data4" label-width="auto">
            <div v-for="(item,i) in tree" :key="item.id">
              <div style="color:rgba(37, 39, 43, 1);font-weight: 600">
                {{ item?.meta?.name }}
              </div>
              <el-checkbox-group v-model="checkedList[`checkedCities${i+1}`]" :disabled="type=='detail'"
                                 @change="handleCheckedCitiesChange(checkedList[`checkedCities${i+1}`],item,i+1)">
                <template v-for="li in item.detail">
                  <el-checkbox :label="li.id" :key="li.id" v-if="li?.meta?.defaultAuth!==true">
                    {{ li?.meta?.name || li.name }}
                  </el-checkbox>
                </template>
              </el-checkbox-group>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="type!=='detail'">
                <template>
                    <el-button @click="submitBtn" v-if="type!='edit'">立即创建</el-button>
                    <el-button @click="submitBtn('edit')" v-else>保存</el-button>
                </template>
                <el-button type="primary" @click="resetForm">重置</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import rules from "@/util/js/formRules";
import {addEUServiceLi, alterEUServiceLi} from "@/api/workbenche";

export default {
  props: ['type'],
  data() {
    return {
      show: false,
      baseList: {
        ssq: [],
      },
      form: {
        data1: {
          id: '',
          name: '',
          userphone: '',
          phone: '',
          companyname: '',
          imagehead: '',
          registeraddress: '',
          bankno: '',
          contracttotal: '',
          contractunit: '',
          cardno: '',
          cardimg1: '',
          cardimg2: '',
          adcode: '',
          city: '',//城市
        },
        data2: {
          id: '',
          companyname: '',
          cardurl: '',
        },
        data3: {
          id: '',
          orangeaccount: '',
          orangeaccountname: '',
          orangeaccountbank: '',
          orangecomanycode: ''
        },
        data4:[]
      },
      rules: {
        "data1.name": rules(),
        "data1.imagehead": rules(),
        "data1.companyname": rules(),
        "data1.cardno": rules('card'),
        "data1.registeraddress": rules(),
        "data1.phone": rules(),
        "data1.userphone": rules(),
        "data1.adcode": rules(),
        "data1.city": rules(),
        "data2.cardurl": rules(),
        "data1.cardimg1": rules(),
        "data1.cardimg2": rules(),
        "data1.contracttotal": rules('moneyInt'),
        "data1.contractunit": rules('money'),
        "data3.orangeaccount": rules('','',false),
        "data3.orangeaccountname": rules('','',false),
        "data3.orangeaccountbank": rules('','',false),
        "data3.orangecomanycode": rules('','',false),
        "data4":rules()
      },
      checkAllList: {
        checkAll1: false,
        // checkAll2: false,
        // checkAll3: false,
        // checkAll4: false,
        // checkAll5: false,
      },
      checkedList: {
        checkedCities1: [],
        // checkedCities2: [],
        // checkedCities3: [],
        // checkedCities4: [],
        // checkedCities5: [],
      },
      indeterminateList: {
        isIndeterminate1: false,//全选不确定状态
        // isIndeterminate2: false,//全选不确定状态
        // isIndeterminate3: false,//全选不确定状态
        // isIndeterminate4: false,//全选不确定状态
        // isIndeterminate5: false,//全选不确定状态
      },
      tree: [],//功能树
      ids: {
        id1: [],
        // id2: [],
        // id3: [],
        // id4: [],
        // id5: [],
      },//功能树id
    };
  },
  created() {
    const ssq = JSON.parse(JSON.stringify(this.$store.state.regionData))
    ssq.shift()
    this.baseList.ssq = ssq
    const tree = JSON.parse(localStorage.getItem('paas_routerMenu'))
    this.tree = tree?.detail?.filter(item => {
      if (item.name === '易用众包服务商') {
        item.meta = JSON.parse(item.meta)
        item.detail.forEach(DItem => {
          DItem.meta = JSON.parse(DItem.meta)
        })
        return item
      }
    })
  },
  methods: {
    getIds(list){
      let arr = []
      if(list.detail&&list.detail.length){
        arr.push(list.id)
      }
      const getIds = function(data){
        data.forEach(item=>{
          arr.push(item.id)
          if(item.detail&&item.detail.length){
            getIds(item.detail)
          }
        })
        return arr
      }
      return getIds(list.detail)
    },
    handleCheckedCitiesChange(value, list, index) {
      const defaultAuthList = []//默认隐藏选中的路由
      // const filterList = list.detail.filter(item => {
      //   if (item?.meta?.defaultAuth !== true) {
      //     return item
      //   } else {
      //     defaultAuthList.push(item)
      //   }
      // })
      const sourceList = []
      const newList = JSON.parse(JSON.stringify(list))
      value?.forEach(id => {
        const item = list?.detail?.filter(li => li.id == id)[0]
        if(item){
          sourceList.push(item)
        }
      })
      newList.detail = sourceList.concat(defaultAuthList)
      this.ids[`id${index}`] = this.getIds(newList)//需要的ids
      let startArr = []
      for (let idsKey in this.ids) {
        startArr = startArr.concat(this.ids[idsKey])
      }
      this.form.data4 = startArr
      this.checkedList[`checkedCities${index}`] = value
      this.$refs['ruleForm'].validateField(['data4'])
    },
    //城市数据处理
    cityChange(v) {
      this.form.data1.adcode = v[2]
    },
    submitBtn(type) {
      this.form.data1.imagehead = this.$refs['headerUpload'].url
      this.form.data1.cardimg1 = this.$refs['cardFrontUpload'].url
      this.form.data1.cardimg2 = this.$refs['cardBackUpload'].url
      this.form.data2.cardurl = this.$refs['permitUpload'].url
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.$ELoading()
          const req = {
            data1: {
              id: this.form.data1.id,
              name: this.form.data1.name,
              phone: this.form.data1.phone,
              companyname: this.form.data1.companyname,
              registeraddress: this.form.data1.registeraddress,
              imagehead: this.form.data1.imagehead,
              address: this.form.data1.address,
              contracttotal: parseInt(this.form.data1.contracttotal),
              contractunit: this.form.data1.contractunit,
              cardno: this.form.data1.cardno,
              cardimg1: this.form.data1.cardimg1,
              cardimg2: this.form.data1.cardimg2,
              userphone: this.form.data1.userphone,
              adcode: this.form.data1.adcode,
              bankno: this.form.data3.orangeaccount,
            },
            data2: {
              id: this.form.data2.id,
              companyname: this.form.data1.companyname,
              cardurl: this.form.data2.cardurl,
            },
            data3: {
              id: this.form.data3.id,
              orangeaccount: this.form.data3.orangeaccount||' ',
              orangeaccountname: this.form.data3.orangeaccountname,
              orangeaccountbank: this.form.data3.orangeaccountbank,
              orangecomanycode: this.form.data3.orangecomanycode,
            },
            data4: this.form.data4
          }
          let fun = addEUServiceLi
          if (type === 'edit') {
            fun = alterEUServiceLi
          }
          fun(this.$req(req)).then(res => {
            if (res.code) {
              if (res.code == 1000) {
                if (type === 'edit') {
                  this.$parent.getList().then(() => {
                    this.$EClose()
                  })
                } else {
                  this.$parent.addGetList()
                }
                this.$message.success('操作成功')
                this.show = false
                this.resetForm()
              } else {
                this.$message.error(res.message ? res.message : '操作失败')
                this.$EClose()
              }
            }
          })
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.checkedList[`checkedCities1`] = []
      this.$refs['ruleForm'].resetFields()
      this.$refs['headerUpload'].url = ''
      this.$refs['permitUpload'].url = ''
      this.$refs['cardFrontUpload'].url = ''
      this.$refs['cardBackUpload'].url = ''
    },
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input__inner {
  max-width: 300px;
}

::v-deep .dialogClass {
  width: auto !important;
  max-width: 50vw;
  border-radius: 5px;
  background: rgba(255, 255, 255, 1);
}

::v-deep .el-dialog__title {
  font-size: 15px;
  position: relative;
  padding-left: 15px;
  line-height: 1;
}

::v-deep .el-dialog__title:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(31, 120, 255, 1);
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

::v-deep .el-dialog__body {
  padding: 30px 40px;
  padding-bottom: 10px;
  max-height: calc(100vh - 25vh - 200px);
  overflow-y: auto;
}

.dialog-footer {
  display: flex;
  align-content: center;
  justify-content: center;
}

.dialog-footer > button:nth-of-type(1) {
  width: 40%;
  max-width: 140px;
  max-height: 61px;
  border-radius: 5px;
  background: rgba(31, 120, 255, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
}

.dialog-footer > button:hover {
  opacity: .9;
  transition: all linear .2s;
}

.dialog-footer > button:nth-of-type(2) {
  width: 40%;
  max-width: 140px;
  max-height: 61px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(31, 120, 255, 1);
  color: rgba(31, 120, 255, 1);
  font-size: 14px;
}

.formNode {
  margin-top: 5px;
  box-sizing: border-box;

  .box_title_node {
    margin-bottom: 20px;
  }
}

.formNode_title {
  color: rgba(0, 0, 0, 1);
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
}

.formNode_title:before {
  width: 5px;
  height: 15px;
  border-radius: 4px;
  background: rgba(31, 120, 255, 1);
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>
