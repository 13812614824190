<template>
  <el-dialog
      custom-class="alipayDialog"
      title=""
      center
      :show-close="false"
      :visible.sync="show"
      width="30%"
      top="30vh">
  </el-dialog>
</template>

<script>
export default {
  name: "openAlipay",
  data() {
    return {
      show: false,
      value:''
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .alipayDialog {
  transition: all linear .2s;

  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>