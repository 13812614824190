//表单规则正则表达式
export default function rules(type,emTip,emFlag=true) {
    emTip?emTip:emTip='不能为空'
    let data = [{required:emFlag,message:emTip,trigger:'change'}]
    switch (type) {
        case 'phone':
            data.push({pattern: /^(?:\+?86)?1[3-9]\d{9}$/,message:'手机号校验不通过',trigger:'blur'})
            //^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$
            return data
        case 'bank':
            data.push({pattern: /^\d{16}$|^\d{19}$|^\d{20}$/,message:'卡号验证不通过',trigger:'blur'})
            return data
        case 'card':
            data.push({pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1-2]\d|3[0-1])\d{3}[\dxX]$/,message:'身份证号码验证不通过',trigger:'blur'})
            return data
        case 'name':
            data.push({pattern: /^[\u4e00-\u9fa5a-zA-Z]+$/,message:'姓名验证不通过',trigger:'blur'})
            return data
        case 'order':
            data.push({pattern: /^[^\u4e00-\u9fff\s]+$/,message:'单号验证不通过',trigger:'blur'})
            return data
        case 'numAlphabet':
            data.push({pattern:/^[a-zA-Z0-9]+$/,message: '请输入数字或字母',trigger: 'change'})
            return data
        case 'money':
            data.push({pattern:/^(0|[1-9]\d*)(\.\d{1,2})?$/,message: '请输入有效金额，最多精确到小数点后两位',trigger:'change'})
            data.push({max:11,message: '最多支持12位长度',trigger:'change'})
            return data
        case 'servicetax':
            data.push({pattern:/^1\d\.([1-9]{1,2}|[1-9][1-9])$|^[1-9]\d{0,1}(\.\d{1,2}){0,1}$|^100(\.00){0,2}$/,message: '支持输入1-100的任意数字，保留2位小数',trigger:'blur'})
            return data
        case 'moneyInt':
            data.push({pattern:/^(0|[1-9]\d*)?$/,message: '请输入有效正整数',trigger:'change'})
            data.push({max:12,message: '最多支持12位长度',trigger:'change'})
            return data
        case 'natural':
            data.push({pattern:/^(0|[1-9]\d*)?$/,message: '请输入自然数',trigger:'change'})
            data.push({max:3,message: '最多支持3位长度',trigger:'change'})
            return data
        default:
            return data
    }
}
