<template>
  <div class="task">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "task",
};
</script>

<style scoped>
.task {
  width: 100%;
  height: 100%;
}
</style>
