<template>
  <div class="pageMain">
    <div class="tableMain">
      <div class="tableBox">
        <ETable
          ref="ETable"
          :tableData="tableData"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <template #agerange="{ rowData: rowData }">
            {{ rowData?.minage || 16 }}-{{ rowData?.maxage || 65 }}岁
          </template>
          <template #insurancemoney="{ rowData: rowData }">
            {{ rowData?.insurancemoney }}
            {{
              rowData?.unit != null
                ? rowData?.unit == 0
                  ? "元/天"
                  : "元/月"
                : ""
            }}
          </template>
          <template #renewflag="{ rowData: rowData }">
            <div :class="`status status-${rowData?.renewflag}`">
              {{ rowData?.renewflag === 0 ? "是" : "否" }}
            </div>
          </template>
          <template #createtime="{ rowData: rowData }">
            {{ rowData?.createtime ? getDateStr(rowData?.createtime) : "--" }}
          </template>
          <template #operate="{ rowData: rowData }">
            <el-button type="text" size="small" @click="() => onUpdate(rowData)"
              >修改</el-button
            >
            <el-button type="text" size="small" @click="() => onDelete(rowData)"
              >删除</el-button
            >
          </template>
        </ETable>
      </div>
    </div>
    <updateInsurancType ref="updateInsurancTypeRef"></updateInsurancType>
  </div>
</template>
<script>
import {
  getInsuranceTpyeList,
  delinsuranceTpye,
} from "../../../api/authorityManage";
import md5 from "../../../util/js/md5";
import moment from "@/util/js/moment";

export default {
  name: "platformAdminDefault",
  watch: {},
  data() {
    return {
      tableData: {
        serial: 0,
        currentPage: 0,
        data: [],
        cols: [
          { label: "编码", prop: "insurancecode" },
          { label: "保险类型", prop: "insurancetype" },
          { label: "职业类别", prop: "category" },
          { label: "日保/月保", prop: "insurancetime" },
          { label: "年龄范围", prop: "agerange" },
          { label: "保额", prop: "insuredamount" },
          { label: "费用", prop: "insurancemoney" },
          { label: "是否支持自动续保", prop: "renewflag" },
          { label: "创建时间", prop: "createtime" },
        ],
      },
      //表格列表请求体
      listReq: {
        index: 20,
        page: 1,
        data: {
          insurancetype: "",
          category: "",
        },
      },
      searchBody: {
        searFlag: false,
      },
    };
  },
  components: {
    updateInsurancType: () => import("./updateInsurancType.vue"),
  },
  created() {
    this.getInsuranceTpyeList();
  },
  methods: {
    //获取列表
    getInsuranceTpyeList() {
      this.$ELoading();
      getInsuranceTpyeList(this.$req(this.listReq)).then((res) => {
        if (res.code == 1000) {
          this.tableData.data = res.data;
          console.log(this.tableData.data, "====A===");
        }
        this.$EClose();
      });
    },
    getDateStr(createtime) {
      return moment(createtime).format("YYYY-MM-DD HH:mm:ss");
    },
    onDelete(item) {
      this.$confirm("是否确定删除该数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ELoading("", "正在加载");
        delinsuranceTpye({ id: item.id }).then((res) => {
          this.$EClose();
          if (res.code == 1000) {
            this.$message.success("删除成功");
            this.getInsuranceTpyeList();
          } else {
            this.$message.warning("删除失败");
          }
        });
      });
    },
    handleSizeChange(val) {
      this.listReq.index = val;
      this.listReq.page = 1;
      this.$iApi.paging(this.getInsuranceTpyeList).then(() => {
        this.$EClose();
      });
    },
    handleCurrentChange(val) {
      this.listReq.page = val;
      this.$iApi.paging(this.getInsuranceTpyeList).then(() => {
        this.$EClose();
      });
    },
    onUpdate(item) {
      this.$refs["updateInsurancTypeRef"].show = true;
      this.$refs["updateInsurancTypeRef"].type = "update";
      this.$refs["updateInsurancTypeRef"].formData = {
        id: item.id,
        insurancecode: item.insurancecode,
        insurancetype: item.insurancetype,
        category: item.category,
        insurancetime: item.insurancetime,
        insuredamount: item.insuredamount,
        insurancemoney: item.insurancemoney,
        renewflag: item.renewflag,
        insuredamount: item.insuredamount.split("万")[0],
        unit: item.unit,
        minage: item.minage,
        maxage: item.maxage,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.pageMain {
  width: 100%;
  height: 100%;
}
.tableSearch {
  width: 100%;
  height: 170px;
  padding: 35px 54px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
  font-size: 14px;
}
.fromLabels {
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.el-select {
  width: 240px;
  height: 31px;
  opacity: 1;
  box-sizing: border-box;
  width: 100%;
}
::v-deep .fromLabels .el-input__inner {
  height: 31px !important;
  /*line-height: 31px;*/
}
::v-deep .el-select .el-input .el-select__caret {
  line-height: 31px;
}
.el-input__inner {
  border-radius: 0;
  background: #f4f4f5;
}

::v-deep .el-select .el-input .el-select__caret {
  color: rgba(100, 102, 107, 1);
}

/*______________________________________*/
.tableMain {
  width: 100%;
  height: 100%;
  background: #f5f6fa;
  padding: 0px 20px;
  box-sizing: border-box;
}
.tableBox {
  height: 100%;
  .status {
    display: flex;
    align-items: center;
    &::before {
      content: " ";
      width: 6px;
      height: 6px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 7px;
    }
    &.status-0 {
      color: #43cf7c;
      &::before {
        background: #43cf7c;
      }
    }
    &.status-1 {
      color: #ff8d1a;
      &::before {
        background: #ff8d1a;
      }
    }
  }
}

::v-deep .fromLabels .el-input__inner {
  width: 100%;
  /*padding-left: 75px !important;*/
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
}
/*发布属性*/
::v-deep .popup .el-dialog {
  width: 30%;
  min-width: 600px;
  border-radius: 10px;
  /*margin-top: 10vh !important;*/
}
::v-deep .popup .el-dialog__title {
  width: 64px;
  height: 24px;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
  margin-left: 40px;
  left: -8065px;
  top: -3589px;
}
::v-deep .popup .el-dialog__title:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: rgba(31, 120, 255, 1);
  position: absolute;
  top: 27px;
  left: 40px;
}
::v-deep .popup .el-dialog__body {
  padding: 10px 20px;
}

::v-deep .popup .el-form-item__label {
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  vertical-align: top;
}
::v-deep .popup .el-form-item {
  margin-bottom: 15px;
}
::v-deep .popup .el-col-1 {
  margin: 0 5px;
  text-align: center;
  color: black;
}
::v-deep .popup .el-col-2 {
  width: 1px;
}
::v-deep .popup .el-col-11 {
  width: 130px;
}
::v-deep .popup .el-input__prefix {
  display: none;
}
::v-deep .popup .el-input--suffix .el-input__inner {
  /*height: 31px !important;*/
}
::v-deep .popup .el-input--suffix .el-input__inner {
  padding: 0 15px;
}

::v-deep .popup .el-dialog__footer {
  width: 100%;
  position: relative;
  bottom: 10px;
}

::v-deep .popup .dialog-footer .el-button:nth-of-type(1) {
  background-size: cover;
  border-radius: 5px;
  color: white;
  width: 265px;
  height: 61px;
  opacity: 1;
  background: rgba(31, 120, 255, 1);
  border: 1px solid rgba(31, 120, 255, 1);
}
::v-deep .popup .dialog-footer .el-button:nth-of-type(2) {
  color: rgba(31, 120, 255, 1);
  border-radius: 5px;
  margin-left: 38px;
  width: 205px;
  height: 61px;
  opacity: 1;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    rgba(173, 211, 255, 0.67) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid rgba(31, 120, 255, 1);
}
::v-deep .popup .scroll {
  max-height: 60vh;
  overflow-y: auto;
  padding-bottom: 10px;
  box-sizing: border-box;
  position: relative;
}
::v-deep .popup .scroll .el-input__inner {
  height: 31px;
  line-height: 31px;
}
.dialog-footer {
  text-align: center;
}
::v-deep .popper__arrow::after {
  display: none;
}
::v-deep .popper__arrow {
  display: none !important;
}
::v-deep .popup .el-popper {
  margin-top: 0;
}

/*  按钮*/
.btnLabel_Li > button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnLabel_Li img {
  width: 13px;
}
.btnLabel_Li > button:nth-of-type(1) {
  width: 86px;
  height: 35px;
  opacity: 1;
  border-radius: 7px;
  background: rgba(73, 119, 252, 1);
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: 0.5px solid rgba(73, 119, 252, 1);
}
.btnLabel_Li > button:nth-of-type(2) {
  width: 86px;
  height: 35px;
  opacity: 1;
  border-radius: 7px;
  background: linear-gradient(
    90deg,
    rgba(173, 211, 255, 0.67) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 0.5px solid rgba(73, 119, 252, 1);
  font-size: 12px;
  font-weight: 400;
  color: rgba(73, 119, 252, 1);
}
</style>

