<template>
  <div class="pageMain">
    <div class="tableMain">
      <ETable ref="ETable" :tableData="tableData" :operateWidth="tableData.operateWidth"  @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
        <template #phone="{rowData:rowData}">
          {{rowData.data1&&rowData.data1.phone?rowData.data1.phone:'--'}}
        </template>
        <template #name="{rowData:rowData}">
          {{rowData.data1&&rowData.data1.name?rowData.data1.name:'--'}}
        </template>
        <template #userphone="{rowData:rowData}">
          {{rowData.data1&&rowData.data1.userphone?rowData.data1.userphone:'--'}}
        </template>
        <template #et="{rowData:rowData}">
          {{rowData.data2&&rowData.data2.et?rowData.data2.et+'份':'--'}}
        </template>
        <template #companyname="{rowData:rowData}">
          {{rowData.data1&&rowData.data1.companyname?rowData.data1.companyname:'--'}}
        </template>
        <template #createtime="{rowData:rowData}">
          {{rowData.data1&&rowData.data1.createtime?$iApi.moment(rowData.data1.createtime,'YYYY-MM-DD HH:mm:ss'):'--'}}
        </template>
        <template #status="{rowData:rowData}">
          <div v-if="rowData.data1&&rowData.data1.status==1" style="color: #06ec06">启用中</div>
          <div v-else>停用中</div>
        </template>
        <template #operate="{rowData:rowData}">
          <el-button type="text" size="small" @click="detailService(rowData)">详情</el-button>
          <el-button type="text" size="small" @click="editService(rowData)">编辑</el-button>
          <el-button type="text" size="small" @click="recharge(rowData)">充值</el-button>
          <el-button type="text" size="small" @click="gotoStoreMng(rowData)">商家管理</el-button>
          <template>
            <el-button type="text" size="small" v-if="rowData.data1.auth==1">已认证</el-button>
            <el-button type="text" size="small" @click="openFDD(rowData)" v-else>开通法大大</el-button>
          </template>
          <template>
            <el-button type="text" size="small" v-if="checkAliPay(rowData)" disabled>已开通支付宝</el-button>
            <el-button type="text" size="small" v-else-if="rowData.data4?.[0]?.aliaccountbook||rowData.data4?.[0]?.aliinfo" @click="openAlipay(rowData,false)" >开通失败</el-button>
            <el-button type="text" size="small" @click="openAlipay(rowData)" v-else>开通支付宝</el-button>
          </template>
          <template>
            <el-button type="text" size="small" @click="alterVipStatus(rowData,0)" v-if="rowData.data1&&rowData.data1.status==1">停用</el-button>
            <el-button type="text" size="small" @click="alterVipStatus(rowData,1)" v-else>启用</el-button>
          </template>
        </template>
      </ETable>
    </div>
    <editService ref="editService" type="edit"></editService>
    <detailService ref="detailService" type="detail"></detailService>
    <recharge ref="recharge"></recharge>
  </div>
</template>

<script>
  import {pcToAuthenticate} from "@/api/public";
  import {alterVipAccEd, getVipAccList, getSerAlipayCode} from "@/api/workbenche";

  export default {
    name: "end",
    components:{
      editService:()=>import('./component/addSvs'),
      detailService:()=>import('./component/addSvs'),
      recharge:()=>import('./component/recharge'),
    },
    data() {
      return {
        //store
        storeIf:false,//商家管理
        //表格列表请求体
        listReq:{
          index:20,
          page:1,
          data:{
            serviceauth:1,
          }
        },
        tableData: {
          currentPage:0,
          data: [],
          cols:[
            {label:'用户名',prop:'phone'},
            {label:'姓名',prop:'name'},
            {label:'手机号',prop:'userphone'},
            {label:'公司名称',prop:'companyname'},
            {label:'合同余量',prop:'et'},
            {label:'创建时间',prop:'createtime'},
            {label:'状态',prop:'status'},
          ],
          operateWidth:'300px'
        },
      };
    },
    created() {
      this.$ELoading()
      this.getList().then(()=>{
        this.$EClose()
      })
    },
    methods:{
      //判断支付宝开通状态
      checkAliPay(item){
        try {
          const aliaccountbook = JSON.parse(item?.data4?.[0]?.aliaccountbook)
          if(aliaccountbook?.alipay_fund_accountbook_create_response?.code==10000){
            return true
          }else{
            return false
          }
        }catch (err){
          return false
        }
      },
      //开通支付宝
      async openAlipay(item,flag){
        const res = await getSerAlipayCode(item?.data1?.id)
        if(!flag) this.$message.warning('再次开通中')
        if(res){
          if(res?.code==1000){
            const wd = window.open('', '_blank')
            wd.document.write(res.message)
          }else{
            this.$message.error(res.message||'操作失败')
          }
        }

      },
      //获取列表
      getList(){
        return new Promise(resolve => {
          getVipAccList(this.$req(this.listReq)).then(res=>{
            if(res.code){
              resolve()
              if(res.code==1000){
                this.tableData.data=res.data
              }else{
                this.$message.error(res.message?res.message:'操作失败')
              }
            }
          })
        })
      },
      //详情
      detailService(item){
        this.editService(item,'detailService')
      },
      //编辑
      editService(item,name='editService'){
        //详情流程
        if(name==='detailService'){
          this.$refs[name].show=true
          this.$nextTick(()=>{
            this.$refs[name].form.data1.id=item?.data1?.id
            this.$refs[name].form.data2.id=item?.data3?.id
            this.$refs[name].form.data3.id=item?.data4[0]?.id
            this.$refs[name].$refs['headerUpload'].url=item?.data1?.imagehead
            this.$refs[name].$refs['permitUpload'].url=item?.data3?.cardurl
            this.$refs[name].$refs['cardFrontUpload'].url=item?.data1?.cardimg1
            this.$refs[name].$refs['cardBackUpload'].url=item?.data1?.cardimg2
            this.$refs[name].form.data1.imagehead=item?.data1?.imagehead
            this.$refs[name].form.data2.cardurl=item?.data3?.cardurl
            this.$refs[name].form.data1.cardimg1=item?.data1?.cardimg1
            this.$refs[name].form.data1.cardimg2=item?.data1?.cardimg2
            this.$refs[name].form.data1.city=item?.data1?.adcode?this.$iApi.dictPropArea(item?.data1?.adcode).join('/')||'':''
            this.$refs[name].form.data1.adcode=item?.data1?.adcode
            this.$refs[name].form.data1.companyname=item?.data1?.companyname
            this.$refs[name].form.data1.registeraddress=item?.data1?.registeraddress
            this.$refs[name].form.data1.name=item?.data1?.name
            this.$refs[name].form.data1.phone=item?.data1?.phone
            this.$refs[name].form.data1.cardno=item?.data1?.cardno
            this.$refs[name].form.data1.userphone=item?.data1?.userphone
            this.$refs[name].form.data1.contracttotal=item?.data1?.contracttotal
            this.$refs[name].form.data1.contractunit=item?.data1?.contractunit
            this.$refs[name].form.data3.orangeaccount=item?.data4[0]?.orangeaccount
            this.$refs[name].form.data3.orangeaccountname=item?.data4[0]?.orangeaccountname
            this.$refs[name].form.data3.orangeaccountbank=item?.data4[0]?.orangeaccountbank
            this.$refs[name].form.data3.orangecomanycode=item?.data4[0]?.orangecomanycode
            const itemIds = item?.data5.map(item=>item.id)
            this.$refs[name].tree.forEach((item,i)=>{
              if(item.name === '易用众包服务商'){
                const value = item.detail.map(d_item=>{
                  if(itemIds.includes(d_item.id))return d_item.id
                })
                this.$refs[name].tree.forEach((item,i)=>{
                  if(item.name === '易用众包服务商'){
                    this.$refs[name].handleCheckedCitiesChange(value,item,i+1)
                  }
                })
              }
            })
            this.$nextTick(()=>{
              this.$refs[name].$refs['ruleForm'].clearValidate()
            })
          })
        }else{
          this.$refs[name].show=true
          this.readonlyFlag=false
          this.$nextTick(()=>{
            this.$refs[name].form.data1.id=item?.data1?.id
            this.$refs[name].form.data2.id=item?.data3?.id
            this.$refs[name].form.data3.id=item?.data4[0]?.id
            this.$refs[name].$refs['headerUpload'].url=item?.data1?.imagehead
            this.$refs[name].$refs['permitUpload'].url=item?.data3?.cardurl
            this.$refs[name].$refs['cardFrontUpload'].url=item?.data1?.cardimg1
            this.$refs[name].$refs['cardBackUpload'].url=item?.data1?.cardimg2
            this.$refs[name].form.data1.imagehead=item?.data1?.imagehead
            this.$refs[name].form.data2.cardurl=item?.data3?.cardurl
            this.$refs[name].form.data1.cardimg1=item?.data1?.cardimg1
            this.$refs[name].form.data1.cardimg2=item?.data1?.cardimg2
            this.$refs[name].form.data1.city=item?.data1?.adcode?this.$iApi.dictPropArea(item?.data1?.adcode,'adcode'):[]
            this.$refs[name].form.data1.adcode=item?.data1?.adcode
            this.$refs[name].form.data1.companyname=item?.data1?.companyname
            this.$refs[name].form.data1.registeraddress=item?.data1?.registeraddress
            this.$refs[name].form.data1.name=item?.data1?.name
            this.$refs[name].form.data1.phone=item?.data1?.phone
            this.$refs[name].form.data1.cardno=item?.data1?.cardno
            this.$refs[name].form.data1.userphone=item?.data1?.userphone
            // this.$refs[name].form.data1.contracttotal=String(item?.data1?.contracttotal)
            // this.$refs[name].form.data1.contractunit=String(item?.data1?.contractunit)
            this.$refs[name].form.data3.orangeaccount=item?.data4[0]?.orangeaccount
            this.$refs[name].form.data3.orangeaccountname=item?.data4[0]?.orangeaccountname
            this.$refs[name].form.data3.orangeaccountbank=item?.data4[0]?.orangeaccountbank
            this.$refs[name].form.data3.orangecomanycode=item?.data4[0]?.orangecomanycode
            const itemIds = item?.data5.map(item=>item.id)
            this.$refs[name].tree.forEach((item,i)=>{
              if(item.name === '易用众包服务商'){
                const value = item.detail.map(d_item=>{
                  if(itemIds.includes(d_item.id))return d_item.id
                })
                this.$refs[name].tree.forEach((item,i)=>{
                  if(item.name === '易用众包服务商'){
                    this.$refs[name].handleCheckedCitiesChange(value,item,i+1)
                  }
                })
              }
            })

            this.$nextTick(()=>{
              this.$refs[name].$refs['ruleForm'].clearValidate()
            })
          })
        }
      },
      //充值
      recharge(item){
        this.$refs['recharge'].form.id=item?.data2?.id
        this.$refs['recharge'].form.residue=item?.data2?.et
        this.$refs['recharge'].form.contractunit=item?.data1?.contractunit
        this.$refs['recharge'].show=true
        this.$nextTick(()=>{
          this.$refs['recharge'].resetForm()
        })
      },
      //商家管理
      gotoStoreMng(item){
        this.$parent.serviceItem=item
        this.$parent.storeIf=true
      },
      //停用/启用
      alterVipStatus(item,status){
        if(!status){
          this.$EConfirm('此操作将停用该会员账号，该会员及该会员创建的其他帐号将无法登录，是否继续？','停用会员账号','warning').then(()=>{
            this.$ELoading()
            alterVipAccEd(item.data1.id,status).then(res=>{
              if(res.code){
                if(res.code==1000){
                  this.getList().then(()=>{
                    this.$EClose()
                  })
                  this.$message.success('操作成功')
                }else{
                  this.$EClose()
                  this.$message.error(res.message?res.message:'操作失败')
                }
              }
            })
          }).catch(()=>{})
        }else{
          this.$ELoading()
          alterVipAccEd(item.data1.id,status).then(res=>{
            if(res.code){
              if(res.code==1000){
                this.getList().then(()=>{
                  this.$EClose()
                })
                this.$message.success('操作成功')
              }else{
                this.$EClose()
                this.$message.error(res.message?res.message:'操作失败')
              }
            }
          })
        }
      },
      //开通法大大
      openFDD(item){
        if(item.data1.fadadaurl){
          window.open(item.data1.fadadaurl)
        }else{
          this.$ELoading()
          pcToAuthenticate(item.data1.id).then(res=>{
            if(res.code){
              if(res.code==1000){
                this.getList().then(()=>{
                  this.$EClose()
                })
                window.open(res.data.data.url)
              }else{
                this.$EClose()
                this.$message.error(res?.data?.msg||res.message||'操作失败')
              }
            }
          })
        }
      },
      handleSizeChange(val) {
        this.listReq.index=val
        this.listReq.page=1
        this.$iApi.paging(this.getList).then(()=>{
          this.$EClose()
        })
      },
      handleCurrentChange(val) {
        this.listReq.page=val
        this.$iApi.paging(this.getList).then(()=>{
          this.$EClose()
        })
      },
    },
  };
</script>

<style scoped>
  .pageMain {
    width: 100%;
    height: 100%;
    padding: 16px 19px 0 18px;
    box-sizing: border-box;
  }
  .tableMain{
    width: 100%;
    height: 100%;
  }
</style>
