<template>
    <el-dialog title="基础设置(必填)" :visible.sync="dialogVisible" custom-class="dialogCustomClass" @close="alterDialogHandle">
        <div class="scroll" ref="scroll" v-if="alterReq">
            <el-form :model="alterReq" :rules="rules"  :inline-message="true" ref="alterForm">
                <el-form-item label="模板名称" :label-width="formLabelWidth"  prop="name">
                    <el-input v-model="alterReq.name" size="small" placeholder="请输入模板名称" class="templateNameInput"  maxlength="50" show-word-limit  clearable></el-input>
                </el-form-item>
                <el-form-item label="合同类型"  :label-width="formLabelWidth"  prop="type">
                    <el-radio-group v-model="alterReq.type" >
<!--                        <el-radio label="1">劳务合同</el-radio>-->
<!--                        <el-radio label="2">劳动合同</el-radio>-->
<!--                        <el-radio label="3">用工协议</el-radio>-->
<!--                        <el-radio label="4">离职合同</el-radio>-->
                      <el-radio label="5">服务协议</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="模板编号" :label-width="formLabelWidth" prop="docno">
                    <el-input v-model="alterReq.docno" size="small" placeholder="请输入模板编号"  show-word-limit disabled clearable></el-input>
                </el-form-item>
                <div class="tabTwo">签署信息设置</div>
                <div v-for="(item,i) in fieldList" :key="i">
                    <el-form-item :label="item.name" :label-width="formLabelWidth">
                        <!--  <el-form-item :label="item.name" :label-width="formLabelWidth"  :prop="'value'+i">-->
                        <el-input v-model="alterReq[`value${i}`]" size="small" :disabled="item.name=='公司名称'?true:false" :placeholder="item.name=='公司名称'?'此处为公司填写':'请输入'+item.name"  show-word-limit  clearable></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="activeModifySave('alterForm')">
                    保存
                </el-button>
            </div>
        </div>
        <el-skeleton  class="mainBox" :rows="18" animated  v-else />
    </el-dialog>
</template>

<script>
    import {alterETContract, getCompanyList} from "@/api/workbenche";

    export default {
        name: "alterDialogCmp",
        data(){
            return{
                list:{
                    projectList:[],
                    mainCompanyList:'',//主体企业列表
                },
                dialogVisible:false,
                //当前修改对象的属性 回显
                alterReq: {
                    "projectid":'',
                    "type": "5",
                    "docno": "",
                    "name": "",
                    "id": "",
                    "cloumnsjson": ""
                },
                forReq:{},
                rules: {
                    projectid: [
                        { required: false, message: '不能为空', trigger: 'change' },
                    ],
                    type: [
                        { required: true, message: '不能为空', trigger: 'change' },
                    ],
                    name: [
                        { required: true, message: '不能为空', trigger: 'change' },
                    ],
                    docno: [
                        { required: true, message: '不能为空', trigger: 'change' },
                    ],
                },
                formLabelWidth: '120px',
                fieldList:[],
            }
        },
        created() {
        },
        methods:{
            //修改dialog关闭事件
            alterDialogHandle(){
                this.$refs['alterForm'].clearValidate()
            },
            //获取更多合作企业
            moreCompany(){
                this.$ELoading()
                this.getCompanyReq.pageNo++
                getCompanyList(this.getCompanyReq).then(res=>{
                    this.$EClose()
                    if(res.code==1000){
                        this.list.companyList=this.list.companyList.concat(res.data.data)
                    }
                })
            },
            //修改保存
            activeModifySave(formName){
                Object.keys(this.alterReq).forEach(item=>{
                    if(item.includes('value')){
                        Object.keys(this.forReq).forEach((XItem,i)=>{
                            this.forReq[i].value=this.alterReq[`value${i}`]
                        })
                    }
                })
                this.alterReq.cloumnsjson=this.forReq

                this.$refs[formName].validate((valid)=>{
                    if (valid) {
                        Object.keys(this.alterReq).forEach(item=>{
                            if(item.includes('value')){
                                delete this.alterReq[item]
                            }
                        })
                        Object.keys(this.alterReq).forEach(item=>{
                            if(!this.alterReq[item]){
                                delete this.alterReq[item]
                            }
                        })
                        alterETContract(this.alterReq).then(res=>{
                            if(res.code==1000){
                                this.$parent.getTableData()
                                this.$message.success('保存成功')
                                this.dialogVisible=false
                            }
                        })
                    } else {
                        return false
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    /*创建账号属性*/
   ::v-deep .dialogCustomClass{
        border-radius: 10px;
        .el-dialog__title{
            width: 64px;
            height: 24px;
            opacity: 1;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 1);
            text-align: left;
            vertical-align: top;
            margin-left: 40px;
        }
       .el-dialog__title:before{
           content:'';
           width: 10px;
           height: 10px;
           border-radius: 100%;
           background:rgba(31, 120, 255, 1)  ;
           position: absolute;
           top: 26px;
           left: 40px;
       }
       .el-dialog__body{
           padding: 10px 20px;
       }
       .el-input{
           max-width: 50%;
       }
       .el-select>.el-input{
           max-width: 100%;
       }
       .el-form-item__label{
           opacity: 1;
           font-size: 14px;
           font-weight: 400;
           letter-spacing: 0px;
           color: rgba(0, 0, 0, 1);
           vertical-align: top;
       }
       .el-form-item{
           margin-bottom: 15px;
       }
       .el-col-1{
           margin: 0 5px;
           text-align: center;
       }
       .el-col-2{
           width: 1px;
       }
       .el-col-11{
           width: 120px;
       }
       .el-input__prefix{
           display: none;
       }
       .el-input--prefix .el-input__inner{
           padding: 0;
           padding-left: 10px !important;
       }
       .scroll{
           max-height:70vh;
           overflow-y: auto;
           padding-bottom: 10px;
           box-sizing: border-box;
         .templateNameInput{
           .el-input__inner{
             padding-right:70px;
           }
         }
       }
       /*创建表单发布重置按钮居中*/
       .dialog-footer{
           text-align: center;
           margin-top: 70px;
           margin-bottom: 20px;
           .el-button:nth-of-type(1){
               background-size: cover;
               border-radius: 5px;
               color: white;
               width: 265px;
               height: 61px;
               opacity: 1;
               background: rgba(31, 120, 255, 1);
               border: 1px solid rgba(31, 120, 255, 1);
           }
           .el-button:nth-of-type(2){
               color: rgba(31, 120, 255, 1);
               border-radius: 5px;
               margin-left: 38px;
               width: 205px;
               height: 61px;
               opacity: 1;
               border-radius: 5px;
               background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
               border: 1px solid rgba(31, 120, 255, 1);
           }
       }
       .tabTwo{
           color: rgba(0, 0, 0, 1);
           font-size: 16px;
           font-weight: 400;
           position: relative;
           margin-left: 40px;
           margin-bottom: 20px;
           margin-top: 40px;
       }
       .tabTwo:before{
           content: '';
           width: 6px;
           height: 15px;
           border-radius: 30%;
           background: rgba(31, 120, 255, 1);
           position: absolute;
           top: 4px;
           left: -18px;
       }
       .companyMain .el-input{
           width: auto;
       }
    }
</style>
