import request from "../util/require";
/**
 * 运营管理 获取协议列表
 * @param companyId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getRuleList(data) {
  return request({
    url: "/paasbase/gloableDict/getGloableDictList",
    method: "post",
    data,
  });
}

/**
 * 运营管理 添加协议
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addRuleLi(data) {
  return request({
    url: "/paasbase/gloableDict/addGloableDict",
    method: "post",
    data,
  });
}

/**
 * 运营管理 编辑协议
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterRuleLi(data) {
  return request({
    url: "/paasbase/gloableDict/modifyGloableDict",
    method: "post",
    data,
  });
}

/**
 * 运营管理 删除协议
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function delRuleLi(id) {
  return request({
    url: "/paasbase/gloableDict/deleteGloableDict",
    method: "post",
    data: { id },
  });
}

//24-1-12
/**
 * 会员管理 会员等级 添加等级
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addVipGrade(data) {
  return request({
    url: "/admin/user/level/save",
    method: "post",
    data,
  });
}

/**
 * 会员管理 会员等级 修改等级
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterVipGrade(data) {
  return request({
    url: "/admin/user/level/detail/modify",
    method: "post",
    data,
  });
}

/**
 * 会员管理 会员等级 根据id查询对应权限功能
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getVipGradeTree(id) {
  return request({
    url: "/admin/user/level/get",
    method: "get",
    params: { id },
  });
}
/**
 * 会员管理 会员等级 获取会员列表
 * @param isdelete 0删除/1未删除 status 0启用1禁用
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getVipGradeList(data) {
  return request({
    url: "/admin/user/level/list",
    method: "post",
    data,
  });
}

/**
 * 会员管理 会员等级 删除/停用/启用
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterVipGrades(data) {
  return request({
    url: "/admin/user/level/modify",
    method: "post",
    data,
  });
}

/**
 * 会员管理 会员列表 获取列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getVipAccList(data) {
  return request({
    url: "/admin/init/user/list",
    method: "post",
    data,
  });
}

/**
 * 会员管理 会员列表 账号启用/禁用
 *
 * @param id
 * @param status
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterVipAccEd(id, status) {
  return request({
    url: "/admin/init/user/status/modify",
    method: "post",
    data: { data: { id, status } },
  });
}

/**
 * 会员管理 会员列表 充值合同
 * @param id
 * @param status
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addVipContract(data) {
  return request({
    url: "/admin/init/user/contract/add",
    method: "post",
    data: { data },
  });
}

/**
 * 会员管理 会员列表 新增
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addVipAccLi(data) {
  return request({
    url: "/admin/init/user/save",
    method: "post",
    data,
  });
}

/**
 * 会员管理 会员列表 修改
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterVipAccLi(data) {
  return request({
    url: "/admin/init/user/modify",
    method: "post",
    data,
  });
}

/**
 * 会员管理 会员列表 续费会员
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addVipAccRenew(data) {
  return request({
    url: "/admin/init/user/renew",
    method: "post",
    data: { data },
  });
}
// 会员管理 会员列表 生成开通支付宝安全码
export function getVipAlipayCode(userid) {
  return request({
    url: "/ali/pay/sign",
    method: "post",
    data: { userid },
  });
}
/**
 * 易用众包 商家 列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getEUUserList(data) {
  return request({
    url: "/admin/user/service/business/list",
    method: "post",
    data,
  });
}

/**
 * 易用众包 商家 新增
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addEUUserLi(data) {
  return request({
    url: "/admin/user/service/business/save",
    method: "post",
    data,
  });
}

/**
 * 易用众包 商家 启用/停用
 * @param id,status
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterEUUserLi(id, status) {
  return request({
    url: "/admin/user/service/business/modify",
    method: "post",
    data: { data: { id, status } },
  });
}

//易用众包 - 关联商家 - 发起签约
export function sendEuSign(data) {
  return request({
    url: "/admin/user/service/contract/company/send",
    method: "post",
    data,
  });
}

/**
 * 易用众包 服务商 新增
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addEUServiceLi(data) {
  return request({
    url: "/admin/user/service/save",
    method: "post",
    data,
  });
}

/**
 * 易用众包 服务商 修改
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterEUServiceLi(data) {
  return request({
    url: "/admin/user/service/modify",
    method: "post",
    data,
  });
}
// 易用众包 服务商列表 生成开通支付宝安全码
export function getSerAlipayCode(userid) {
  return request({
    url: "/eu/ali/sign",
    method: "post",
    data: { userid },
  });
}
// 获取列表
export function getCompanyList(data) {
  return request({
    url: "/paasbase/insurance/admin/companyList",
    method: "post",
    data,
  });
}
// 列表
export function getUserList(data) {
  return request({
    url: "/admin/init/user/list",
    method: "post",
    data,
  });
}
//总后台--劳务公司开通微保账户
export function openInsurance(data) {
  return request({
    url: "/paasbase/insurance/admin/openInsurance",
    method: "post",
    data,
  });
}
//总后台--劳务公司开通微保账户(重新提交)
export function reOpenInsurance(data) {
  return request({
    url: "/paasbase/insurance/admin/reOpenInsurance",
    method: "post",
    data,
  });
}

// 总后台---获取方案列表（待审核/已审核）
export function getPlanList(data) {
  return request({
    url: "/paasbase/insurance/admin/getPlanList",
    method: "post",
    data,
  });
}

// 总后台---审核方案
export function auditPlan(data) {
  return request({
    url: "/paasbase/insurance/admin/auditPlan",
    method: "post",
    data,
  });
}
// 总后台--查询劳务公司开户详情
export function openInsuranceDetail(data) {
  return request({
    url: "/paasbase/insurance/admin/openInsuranceDetail",
    method: "post",
    data,
  });
}

// 合同管理-合同模板-获取自定义字段
export function getCustomField(docno) {
  return request({
    url: "/admin/user/service/contractItem/get",
    method: "post",
    data: { docno },
  });
}

// 合同管理-合同模板-获取自定义字段获取添加模板web链接
export function getAddWebUrl(docno) {
  return request({
    url: "/et/company/template/api/add",
    method: "post",
  });
}

// 合同管理-合同模板-添加电子合同模板
export function addETContract(data) {
  return request({
    url: "/admin/user/service/template/add",
    method: "post",
    data,
  });
}
// 合同管理-合同模板-获取电子合同列表
export function getETList(data) {
  return request({
    url: "/admin/user/service/template/search",
    method: "post",
    data,
  });
}

// 合同管理-合同模板-修改电子合同模板
export function alterETContract(data) {
  return request({
    url: "/admin/user/service/template/modify",
    method: "post",
    data,
  });
}

// 合同管理-合同模板-删除电子合同模板
export function delETContract(id) {
  return request({
    url: "/admin/user/service/template/delete",
    method: "post",
    data: { id },
  });
}
// 易用众包---任务管理--列表
export function taskLists(data) {
  return request({
    url: "/eu/task/get/task/admin",
    method: "post",
    data,
  });
}

// 易用众包--任务管理---审核
export function approveTask(data) {
  return request({
    url: "/eu/task/approve",
    method: "post",
    data,
  });
}

