<template>
  <el-drawer
      title=""
      :visible.sync="show"
      :with-header="false">
    <div class="box">
      <div class="box_title" style="margin-bottom: 19px;">{{ type===1?'修改会员等级':'添加会员等级' }}</div>
      <el-form :model="addGradeReq" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
        <el-form-item label="等级名称" prop="data.name">
          <el-input v-model="addGradeReq.data.name" size="small" placeholder="请输入等级名称" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="等级说明" prop="data.remark">
          <el-input v-model="addGradeReq.data.remark" type="textarea" show-word-limit :rows="4" size="small"
                    placeholder="请输入等级名称" maxlength="50"></el-input>
        </el-form-item>
        <div class="box_title_node">会员功能权限</div>
        <div class="formNode">
          <div class="formNode_title">后台功能</div>
          <el-form-item label="" class="tree_checkbox" prop="list">
            <div v-for="(item,i) in tree" :key="item.id">
              <el-checkbox :indeterminate="indeterminateList[`isIndeterminate${i+1}`]"
                           v-model="checkAllList[`checkAll${i+1}`]"
                           @change="handleCheckAllChange(checkAllList[`checkAll${i+1}`],item,i+1,item.id)">
                <div style="color:rgba(37, 39, 43, 1);font-weight: 600">
                  {{ item?.meta?.name }}
                  <span style="font-size: 10px;font-weight: 400;color: rgba(64, 64, 64, 1);letter-spacing: 0px;"
                        v-if="item?.meta?.name==='光速人力云'">（默认开通一键发薪、转账记录、工资条、离职审批）</span>
                  <span style="font-size: 10px;font-weight: 400;color: rgba(64, 64, 64, 1);letter-spacing: 0px;"
                        v-if="item?.meta?.name==='易用签'">（默认开通发起合同、签署记录）</span>
                </div>
              </el-checkbox>
              <el-checkbox-group v-model="checkedList[`checkedCities${i+1}`]"
                                 @change="handleCheckedCitiesChange(checkedList[`checkedCities${i+1}`],item,i+1)">
                <template v-for="li in item.detail">
                  <el-checkbox :label="li.id" :key="li.id" v-if="li?.meta?.defaultAuth!==true">
                    {{ li?.meta?.name || li.name }}
                  </el-checkbox>
                </template>
              </el-checkbox-group>
            </div>
          </el-form-item>
        </div>
        <div class="formNode">
          <div class="formNode_title">会员等级规则</div>
          <el-form :model="gradeForm" :rules="gradeRules" ref="gradeRuleForm" label-width="auto" class="grade-ruleForm">
            <ETable :tableData="tableData" ref="ETable">
              <template #switch="{rowData:rowData}">
                <div>
                  <el-switch v-model="gradeForm[`rule${rowData.switch}`].switch"></el-switch>
                </div>
              </template>
              <template #effective="{rowData:rowData}">
                <el-form-item label="" :prop="`rule${rowData.switch}.effective`">
                  <el-select v-model="gradeForm[`rule${rowData.switch}`].effective" style="width: 100%"
                             v-if="alterIndex==rowData.switch" size="mini">
                    <el-option
                        v-for="item in effectiveBaseList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <span v-else>{{ rowData.effective ? effectiveShow(rowData.effective) : '--' }}</span>
                </el-form-item>
              </template>
              <template #price="{rowData:rowData}">
                <el-form-item label="" :prop="`rule${rowData.switch}.price`">
                  <el-input v-model="gradeForm[`rule${rowData.switch}`].price" v-if="alterIndex==rowData.switch"
                            size="mini"></el-input>
                  <span v-else>{{ rowData.price ? rowData.price + '元' : '--' }}</span>
                </el-form-item>
              </template>
              <template #operate="{rowData:rowData}">
                <el-button type="text" size="small"
                           @click="alterOver(rowData,{effective:gradeForm[`rule${rowData.switch}`].effective,price:gradeForm[`rule${rowData.switch}`].price})"
                           v-if="alterIndex==rowData.switch">完成
                </el-button>
                <el-button type="text" size="small" @click="alterGrade(rowData)" v-else>编辑</el-button>
              </template>
            </ETable>
          </el-form>
          <div class="formNode_title">会员等级权益</div>
          <el-form-item label="赠送合同量" prop="data.contracttotal">
            <div style="width:100%;display:flex;align-items:center">
              <div style="width: 30%">
                <el-input v-model="addGradeReq.data.contracttotal" size="small" placeholder=""
                          maxlength="30"></el-input>
              </div>
              <div style="margin-left: 5px">份</div>
            </div>
          </el-form-item>
          <el-form-item label="合同单价" prop="data.contractunit">
            <div style="width:100%;display:flex;align-items:center">
              <div style="width: 30%">
                <el-input v-model="addGradeReq.data.contractunit" size="small" placeholder="" maxlength="30"></el-input>
              </div>
              <div style="margin-left: 5px">元/份</div>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelForm">取消</el-button>
                    <el-button type="primary" @click="submitOver">确定</el-button>
                </span>
    </div>
  </el-drawer>
</template>

<script>
import rules from "../../../../../util/js/formRules";
import {addVipGrade, alterVipGrade} from "../../../../../api/workbenche";

export default {
  props:{
    type:{
      type: Number,//0增1改
      default: 0
    }
  },
  data() {
    return {
      show: false,
      tableData: {
        data: [
          {effective: "30", switch: 1, price: "200"},
          {effective: "90", switch: 2, price: "600"},
          {effective: "365", switch: 3, price: "2000"}
        ],
        cols: [
          {label: '有效期', prop: 'effective'},
          {label: '是否启用', prop: 'switch'},
          {label: '价格', prop: 'price'},
        ],
        paging: 0,
        selection: 0,
        serial: 0
      },
      rules: {
        "data.name": rules(),
        "data.remark": rules(),
        "data.contracttotal": rules('moneyInt', '', false),
        "data.contractunit": rules('money', '', false),
        "list": rules('', '后台功能不能为空'),
      },
      //添加等级req
      addGradeReq: {
        data: {
          id:'',
          name: '',
          remark: '',
          contracttotal: '',
          contractunit: '',
          recharge1: '',
          recharge2: '',
          recharge3: ''
        },
        list: []
      },
      //编辑等级规则进行标记
      alterIndex: 0,//默认0代表无
      //编辑等级临时表单
      gradeForm: {
        rule1: {
          effective: "30",
          price: "200",
          switch: false
        },
        rule2: {
          effective: "90",
          price: "600",
          switch: false
        },
        rule3: {
          effective: "365",
          price: "2000",
          switch: false
        }
      },
      effectiveBaseList: [
        {label: '一个月（30天）', value: "30"},
        {label: '一季度（90天）', value: "90"},
        {label: '半年度（180天）', value: "180"},
        {label: '一年度（365天）', value: "365"},
        {label: '两年度（730天）', value: "730"},
        {label: '三年度（1095天）', value: "1095"},
      ],
      //等级规则
      gradeRules: {
        "rule1.effective": rules(),
        "rule1.price": rules('moneyInt'),
        "rule2.effective": rules(),
        "rule2.price": rules('moneyInt'),
        "rule3.effective": rules(),
        "rule3.price": rules('moneyInt'),
      },
      checkAllList: {
        checkAll1: false,
        checkAll2: false,
        checkAll3: false,
        checkAll4: false,
        checkAll5: false,
      },
      checkedList: {
        checkedCities1: [],
        checkedCities2: [],
        checkedCities3: [],
        checkedCities4: [],
        checkedCities5: [],
      },
      indeterminateList: {
        isIndeterminate1: false,//全选不确定状态
        isIndeterminate2: false,//全选不确定状态
        isIndeterminate3: false,//全选不确定状态
        isIndeterminate4: false,//全选不确定状态
        isIndeterminate5: false,//全选不确定状态
      },
      tree: [],//功能树
      ids: {
        id1: [],
        id2: [],
        id3: [],
        id4: [],
        id5: [],
      },//功能树id
      productsServiceId:''//产品与服务id
    };
  },
  created() {
    const tree = JSON.parse(localStorage.getItem('paas_routerMenu'))?.detail
    this.tree = tree.filter(item => {
      item?.detail?.forEach(itemS => {
        itemS.meta = JSON.parse(itemS.meta)
      })
      if(item.name === '产品与服务'){
        this.productsServiceId = item.id
      }
      if (item.name != '产品与服务'&&item.name != '易用众包服务商') {
        item.meta = JSON.parse(item.meta)
        return item
      }
    })
  },
  methods: {
    //有效期回显
    effectiveShow(value) {
      for (let key of this.effectiveBaseList) {
        if (value == key.value) {
          return key.label
        }
      }
      return '--'
    },
    //编辑等级规则
    alterGrade(item) {
      this.alterIndex = item.switch
      this.gradeForm[`rule${item.switch}`].effective = item.effective
      this.gradeForm[`rule${item.switch}`].price = item.price
    },
    //完成编辑等级规则
    alterOver(item, values) {
      this.$refs['gradeRuleForm'].validateField([`rule${item.switch}.effective`, `rule${item.switch}.price`], (msg) => {
        if (!msg) {
          this.alterIndex = 0
          this.tableData.data[item.switch - 1].effective = values.effective
          this.tableData.data[item.switch - 1].price = values.price
        } else {
          return false
        }
      })
    },
    getIds(list) {
      let arr = []
      if (list.detail && list.detail.length) {
        arr.push({systemmenuid: list.id})
      }
      const getIds = function (data) {
        data.forEach(item => {
          arr.push({systemmenuid: item.id})
          if (item.detail && item.detail.length) {
            getIds(item.detail)
          }
        })
        return arr
      }
      return getIds(list.detail)
    },
    handleCheckAllChange(val, list, index, fId) {
      const filterList = list.detail.filter(item => item?.meta?.defaultAuth !== true)
      const options = filterList.map(item => item.id)
      this.ids[`id${index}`] = val ? this.getIds(list) : []//需要的ids
      let startArr = []
      for (let idsKey in this.ids) {
        startArr = startArr.concat(this.ids[idsKey])
      }
     if(val){
       startArr.forEach(item=>{
         let has = false
         this.addGradeReq.list.forEach(itemS=>{
           if(item.systemmenuid==itemS.systemmenuid){
             has = true
           }
         })
         if(!has){
           this.addGradeReq.list.push(item)
         }
       })
       // this.addGradeReq.list = this.addGradeReq.list.concat(startArr)
     }else{
       const filterArr = this.getIds(list).map(item=>item.systemmenuid)
       this.addGradeReq.list = this.addGradeReq.list.filter(item=>!filterArr.includes(item.systemmenuid))
     }

      this.checkedList[`checkedCities${index}`] = val ? options : [];
      this.indeterminateList[`isIndeterminate${index}`] = false;
      this.$refs['ruleForm'].validateField(['list'])
    },
    handleCheckedCitiesChange(value, list, index) {
      const defaultAuthList = []//默认隐藏选中的路由
      const filterList = list.detail.filter(item=>{
        if(item?.meta?.defaultAuth!==true){
          return item
        }else{
          defaultAuthList.push(item)
        }
      })
      const sourceList = []
      const newList = JSON.parse(JSON.stringify(list))
      value?.forEach(id => {
        sourceList.push(list?.detail?.filter(li => li.id == id)[0])
      })
      newList.detail = sourceList.concat(defaultAuthList)
      this.ids[`id${index}`] = this.getIds(newList)//需要的ids
      let checkedCount = 0;//
      this.checkedList[`checkedCities${index}`] = value
      const filterIds = filterList.map(item=>item.id)
      value.forEach(id=>{
        filterIds.forEach(idS=>{
          if(idS==id){
            checkedCount++
          }
        })
      })
      this.checkAllList[`checkAll${index}`] = checkedCount === filterIds.length;
      this.indeterminateList[`isIndeterminate${index}`] = checkedCount > 0 &&this.checkAllList[`checkAll${index}`]===false;
      if(!this.indeterminateList[`isIndeterminate${index}`]&&!this.checkAllList[`checkAll${index}`]){
        this.checkedList[`checkedCities${index}`] = []
        this.ids[`id${index}`] = []
      }
      let startArr = []
      for (let idsKey in this.ids) {
        startArr = startArr.concat(this.ids[idsKey])
      }
      this.addGradeReq.list = startArr
      this.$refs['ruleForm']?.validateField(['list'])
    },
    //取消表单
    cancelForm() {
      this.show = false
      this.$refs['ruleForm'].resetFields()
    },
    //提交表单
    submitOver() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.$ELoading()
          let i = 0
          for (let item in this.gradeForm) {
            i++
            const itemData = {
              'status': this.gradeForm[item].switch ? 1 : 0,
              'money': this.gradeForm[item].price,
              'day': this.gradeForm[item].effective
            }
            this.addGradeReq.data[`recharge${i}`] = JSON.stringify(itemData)
          }
          if(this.type===1){
            //修改会员等级
            alterVipGrade(this.$req(this.addGradeReq)).then(res=>{
              if(res){
                if(res.code==1000){
                  this.$message.success('操作成功')
                  this.show=false
                  this.$parent.getList().then(()=>{
                    this.$EClose()
                  })
                }else{
                  this.$message.error(res.message||'操作失败')
                }
              }
            })
          }else{
            //添加会员等级
            addVipGrade(this.$req(this.addGradeReq)).then(res => {
              if (res) {
                if (res.code == 1000) {
                  //全选重置
                  for (let key in this.checkAllList) {
                    this.checkAllList[key] = false
                  }
                  //半选重置
                  for (let key in this.indeterminateList) {
                    this.indeterminateList[key] = false
                  }
                  //多选重置
                  for (let key in this.checkedList) {
                    this.checkedList[key] = []
                  }
                  //套餐开关重置
                  for (const key in this.gradeForm) {
                    this.gradeForm[key].switch = false
                  }
                  //套餐天数金额重置
                  this.tableData.data = [
                    {effective: 30, switch: 1, price: 200},
                    {effective: 90, switch: 2, price: 600},
                    {effective: 365, switch: 3, price: 2000}
                  ]
                  this.$nextTick(() => {
                    this.$refs['ruleForm'].clearValidate()
                  })
                  this.$message.success('操作成功')
                  this.cancelForm()
                  this.$parent.addGetList('操作成功')
                } else {
                  this.$message.error(res.message || '操作失败')
                }
              }
            })
          }

        } else {
          return false
        }
      })
    },
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input__inner {
  max-width: 300px;
}

::v-deep .el-drawer {
  max-width: 700px !important;
  min-width: 550px !important;
}

.box {
  width: 100%;
  height: 100%;
  padding: 25px 20px 25px 35px;
  box-sizing: border-box;
}

.demo-ruleForm {
  width: 100%;
  height: calc(100% - 40px - 19px);
  overflow-y: auto;
}

.box_title, .box_title_node {
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  position: relative;
}

.box_title:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(31, 120, 255, 1);
  content: '';
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
}

.formNode {
  margin-top: 5px;
  box-sizing: border-box;
}

.formNode_title {
  color: rgba(0, 0, 0, 1);
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
}

.formNode_title:before {
  width: 5px;
  height: 15px;
  border-radius: 4px;
  background: rgba(31, 120, 255, 1);
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.tree_checkbox {
  display: flex;
  flex-direction: column;

  .el-checkbox-group {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

::v-deep .tree_checkbox .el-form-item__content {
  margin-left: 25px !important;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dialog-footer > button:nth-of-type(1) {
  width: 98px;
  height: 35px;
  opacity: 1;
  border-radius: 2px;
  background: rgba(244, 244, 245, 1);
  font-size: 14px;
  font-weight: 400;
  color: rgba(71, 71, 71, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-footer > button:hover {
  opacity: .8;
  transition: all linear .2s;
}

.dialog-footer > button:nth-of-type(2) {
  width: 98px;
  height: 35px;
  opacity: 1;
  border-radius: 2px;
  background: rgba(73, 119, 252, 1);
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .grade-ruleForm .el-form-item {
  margin: 20px 0;
}
</style>
