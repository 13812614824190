<template>
    <div class="page">
        <div class="header">
            <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect"
                    text-color="black">
                <el-menu-item :index="activeIndex">保险类别</el-menu-item>
            </el-menu>
            <div class="otherDiv">
               <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                    <el-form-item label="">
                        <el-button type="primary" @click="addInsurancType">
                            <img style="width: 15px; height: 15px" src="../../assets/account/bi.png" alt="" />
                            创建类别
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="searchForm.insurancetype" placeholder="保险类型" aria-placeholder="保险类型" :clearable="true"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="searchForm.category" placeholder="职业类别" aria-placeholder="职业类别" :clearable="true"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSearch">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="main">
            <component :is="vue" ref="vue"></component>
        </div>
        <updateInsurancType ref="updateInsurancTypeRef"></updateInsurancType>
    </div>
</template>

<script>
    export default {
        name: "roleManage",
        data() {
            return {
                activeIndex: "insuranceTypeDefault",
                vue:'insuranceTypeDefault',
                searchForm: {
                    insurancetype: null,
                    category: null,
                },
            };
        },
        components:{
            insuranceTypeDefault:()=>import('./insuranceType/insuranceTypeDefault.vue'),
            updateInsurancType:()=>import('./insuranceType/updateInsurancType.vue')
        },
        methods: {
            handleSelect(key, keyPath) {
                this.vue=key;
            },
            onSearch() {
                console.log('this.$refs.vue', this.$refs['vue'])
                this.$refs['vue'].listReq.page = 1;
                this.$refs['vue'].listReq.data = this.searchForm;
                this.$refs['vue'].getInsuranceTpyeList();
            },
             //添加
            addInsurancType(){
                this.$refs.updateInsurancTypeRef.show = true;
                this.$refs.updateInsurancTypeRef.type = 'create';
            }
        },
    };
</script>
<style scoped>
    .page {
        width: 100%;
        height: 100%;
    }
    .header {
        width: 100%;
        height: 56px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: white;
        border-bottom: solid 1px #e6e6e6;
    }
    .main {
        width: 100%;
        height: calc(100% - 56px);
    }
    .el-menu {
        display: flex;
        align-items: flex-end;
    }
    .el-menu--horizontal > .el-menu-item {
        height: 45px;
    }
    .el-menu.el-menu--horizontal {
        padding-left: 86px !important;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 2px solid rgba(42, 85, 229, 1);
    }
    .main {
        width: 100%;
        height: calc(100% - 57px);
    }
    .el-dropdown-menu__item, .el-menu-item{
        padding: 0;
        margin: 0 20px !important;
    }
    .otherDiv {
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        gap: 9px;
        .el-form-item {
            margin-bottom: 0;
        }
    }

    .otherDiv button {
        width: 123px;
        height: 39px;
        border-radius: 4px;
        background: #4977FC;
        color: rgba(255, 255, 255, 1);
    }

    .otherDiv button:hover {
        opacity: 0.9;
    }
</style>
