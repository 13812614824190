<template>
  <div id="sonPage">
    <div class="grandsonPage">
      <div class="head">
        <div class="card">
          <div class="status">
            <span :class="`state-${propGet?.status}`">{{
              statusChange(propGet?.status)
            }}</span>
          </div>
          <div class="flex-x-start name-salary">
            <span class="taskName">{{ propGet?.taskname || "--" }}</span>
            <div class="salary flex-y-end">
              <div>
                {{
                  propGet?.salary?.slice(0, propGet?.salary.indexOf("元")) ||
                  "--"
                }}
              </div>
              <div class="flex-y-end">
                {{
                  propGet?.salary?.slice(propGet?.salary.indexOf("元")) || "--"
                }}
              </div>
            </div>
          </div>
          <div class="tags flex-x-wrap">
            <div
              class="tag flex"
              v-for="(item, i) in propGet?.labels?.split(',') || []"
              :key="i"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="desc">
          <div class="desc-node">任务描述</div>
          <div>
            <span class="desc-son-node">工作内容：</span>
            <div class="desc-son-nodeContent">
              {{ propGet?.content || "--" }}
            </div>
          </div>
          <div style="margin-top: 20px">
            <span class="desc-son-node">计费模式：</span>
            <div class="desc-son-nodeContent">
              {{ propGet?.billingmode || "--" }}
            </div>
          </div>
          <div style="margin-top: 20px">
            <span class="desc-son-node">服务时间：</span>
            <div class="desc-son-nodeContent">
              {{ propGet?.servicetime || "--" }}
            </div>
          </div>
          <div class="desc-node" style="margin-top: 30px">工作信息</div>
          <div>
            <span class="desc-son-node"
              >服务地点：
              <div class="desc-son-nodeContent">
                {{
                  $iApi
                    .dictPropArea(propGet?.adcode)
                    ?.toString()
                    ?.replaceAll(",", "") + propGet?.address || "--"
                }}
              </div>
            </span>
          </div>
          <div class="desc-node" style="margin-top: 20px">任务选择</div>
          <div class="desc-son-nodeContent">大厅任务</div>
          <!-- 驳回原因 -->
          <div v-if="propGet?.status === '4'">
            <div
              class="desc-node"
              style="margin-top: 20px; color: rgba(255, 87, 51, 1)"
            >
              驳回原因
            </div>
            <div
              class="desc-son-nodeContent"
              style="color: rgba(255, 87, 51, 1)"
            >
              {{ propGet?.rejectreason || "--" }}
            </div>
          </div>
        </div>
        <!-- 按钮 -->
        <div
          class="flex"
          v-if="propGet?.status === '3' || propGet?.status === '6'"
        >
          <div class="btn1" @click="checkStatus('fail')">审核驳回</div>
          <div class="btn2" @click="checkStatus('pass')">审核通过</div>
        </div>
      </div>
    </div>
    <el-dialog
      title=" 驳回原因"
      custom-class="dialogClass"
      :visible.sync="show"
      width="30%"
    >
      <el-input
        v-model="listReq.rejectreason"
        type="textarea"
        show-word-limit
        :rows="4"
        placeholder="请输入驳回原因......"
        maxlength="40"
      ></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  approveTask, // 任务审核
} from "@/api/workbenche";
export default {
  name: "default",
  data() {
    return {
      show: false,
      propGet: null,
      listReq: {
        id: "", // 任务id
        userid: "",
        status: "", // 状态
        rejectreason: "", // 驳回原因
      },
    };
  },
  created() {
    this.propGet = this.$sessionGet();
    this.listReq.id = this.propGet.id;
    this.listReq.userid = this.propGet.userid;
  },
  methods: {
    statusChange(status) {
      switch (status) {
        case "0":
          return "已通过";
          break;
        case "3":
          return "待审核";
          break;
        case "4":
          return "已驳回";
          break;
        case "6":
          return "待审核";
          break;
        default:
          return "--";
      }
    },
    // 审核
    checkStatus(e) {
      console.log(e, "----");
      if (e == "pass") {
        // 通过
        this.listReq.status = 0;
        this.listReq.rejectreason = "";
        this.getList();
      } else if (e == "fail") {
        this.show = true;
        // 驳回
        if (this.propGet.status == 3) {
          // 待审核
          this.listReq.status = 4;
        } else if (this.propGet.status == 6) {
          // 私密转公开待审核
          this.listReq.status = 1;
        }
      }
    },
    cancel() {
      this.show = false;
      this.propGet.rejectreason = "";
    },
    confirm() {
      if (!this.listReq.rejectreason.trim()) {
        this.$message.error("请输入驳回原因");
        return;
      }
      this.getList();
      this.cancel();
    },

    getList() {
      return new Promise((resolve) => {
        approveTask(this.$req(this.listReq))
          .then((res) => {
            resolve();
            if (res) {
              this.$EClose();
              if (res.code == 1000) {
                this.$router.go(-1);
              } else {
                this.$message.error(res.message || "操作失败");
              }
            }
          })
          .catch(() => {
            resolve();
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
#sonPage {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;

  .filterBox {
    width: 100%;
    //height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    &_left {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &_item {
        display: flex;
        align-items: center;
        margin-right: 40px;

        &_label {
          font-size: 14px;
          font-weight: 400;
          color: rgba(84, 84, 84, 1);
          margin-right: 12px;
        }
      }
    }

    &_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &_item {
        display: flex;
        align-items: center;
      }
    }
  }

  .componentBox {
    width: 100%;
    height: calc(100% - 58px);
    background: white;
    .remark {
      max-height: 200px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  @mixin public {
    width: 70px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    span:nth-child(1) {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }

  .cellState0 {
    @include public;
    background: rgba(255, 141, 26, 0.2);
    color: rgba(255, 141, 26, 1);
    span:nth-child(1) {
      background: rgba(255, 141, 26, 1);
    }
  }
  .cellState1 {
    @include public;
    background: rgba(67, 207, 124, 0.2);
    color: rgba(67, 207, 124, 1);
    span:nth-child(1) {
      background: rgba(67, 207, 124, 1);
    }
  }
  .cellState2 {
    @include public;
    background: rgba(255, 87, 51, 0.2);
    color: rgba(255, 87, 51, 1);
    span:nth-child(1) {
      background: rgba(255, 87, 51, 1);
    }
  }
  .cellState4 {
    @include public;
    background: rgba(245, 246, 250, 1);
    color: rgba(181, 181, 181, 1);
    span:nth-child(1) {
      background: rgba(181, 181, 181, 1);
    }
  }
}

.flex-x-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-y-end {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.flex-x-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.state-0 {
  color: #43cf7c;
}

.state-3,
.state-6 {
  color: #ff8d1a;
}

.state-4 {
  color: #ff5733;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dialog-footer > button:nth-of-type(1) {
  width: 98px;
  height: 35px;
  opacity: 1;
  border-radius: 2px;
  background: rgba(244, 244, 245, 1);
  font-size: 14px;
  font-weight: 400;
  color: rgba(71, 71, 71, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-footer > button:hover {
  opacity: 0.8;
  transition: all linear 0.2s;
}

.dialog-footer > button:nth-of-type(2) {
  width: 98px;
  height: 35px;
  opacity: 1;
  border-radius: 2px;
  background: rgba(73, 119, 252, 1);
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn1 {
  cursor: pointer;
  width: 123px;
  height: 30px;
  opacity: 1;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background: rgba(252, 85, 48, 1);
  margin-right: 10px;
}
.btn2 {
  width: 123px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  background: rgba(66, 207, 108, 1);
}
.grandsonPage {
  width: 100%;
  height: 100%;
  padding: 20px 15px 35px;
  box-sizing: border-box;
  overflow-y: auto;
  .desc {
    .desc-node {
      font-size: 18px;
      font-weight: 600;
      line-height: 25.2px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 10px;
    }
    .desc-son-node {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      color: rgba(73, 119, 252, 1);
    }
    .desc-son-nodeContent {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      color: rgba(84, 84, 84, 1);
      margin-top: 6px;
      white-space: pre-line;
    }
  }
  .head {
    width: 100%;
    .card {
      width: 100%;
      height: 146px;
      border-radius: 10px;
      background: rgba(63, 85, 106, 1);
      color: rgba(255, 255, 255, 1);
      padding: 20px 20px 20px 30px;
      box-sizing: border-box;
      position: relative;
      .operatorBox {
        position: absolute;
        right: 20px;
        top: 20px;
      }
      .status {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        color: rgba(255, 255, 255, 1);
        .statusOperate {
          margin-left: 32px;
          &:hover {
            cursor: pointer;
            opacity: 0.9;
          }
          img {
            margin-right: 6px;
            font-size: 14px;
          }
        }
      }
      .name-salary {
        margin-top: 8px;
        .taskName {
          font-size: 28px;
          font-weight: 600;
          line-height: 40px;
          color: rgba(255, 255, 255, 1);
        }
        .salary {
          font-weight: 600;
          margin-left: 12px;
          color: rgba(255, 141, 26, 1);
          div:nth-of-type(1) {
            font-size: 24px;
          }
          div:nth-of-type(2) {
            height: 100%;
            font-size: 14px;
            margin-left: 5px;
            line-height: 25px;
          }
        }
      }
      .tags {
        margin-top: 10px;
        .tag {
          padding: 4px 10px;
          box-sizing: border-box;
          border-radius: 4px;
          background: rgba(72, 95, 113, 1);
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 16.8px;
          color: rgba(255, 255, 255, 1);
          margin-right: 15px;
        }
      }
    }
  }
  .content {
    margin-top: 20px;
  }
  .foot {
    .personList {
      .personLi {
        margin: 15px 60px 15px 0;
        .personDesc {
          color: rgba(84, 84, 84, 1);
          margin-left: 10px;
          font-size: 10px;
          .signTag {
            width: 46px;
            height: 22px;
            border-radius: 4px;
            background: rgba(181, 181, 181, 1);
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            color: rgba(255, 255, 255, 1);
            margin-left: 10px;
          }
          .signTagAct {
            width: 46px;
            height: 22px;
            border-radius: 4px;
            background: rgba(73, 119, 252, 1);
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            color: rgba(255, 255, 255, 1);
            margin-left: 10px;
          }
          .info {
            font-size: 12px;
            font-weight: 400;
            line-height: 17.38px;
            color: rgba(150, 150, 150, 1);
            span {
              padding: 0 7px;
              position: relative;
              &:nth-of-type(n + 2):after {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                content: "";
                width: 1px;
                height: 10px;
                background: rgba(150, 150, 150, 1);
              }
            }
            span:nth-of-type(1) {
              padding-left: 0;
            }
          }
        }
        img {
          width: 46px;
          height: 46px;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
