<template>
  <div class="pageMain">
    <div class="tableMain">
      <div class="tableBox">
        <ETable
          ref="ETable"
          :tableData="tableData"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <template #companyname="{ rowData: rowData }">
            {{ rowData?.companyname || "--" }}
          </template>
          <template #creator="{ rowData: rowData }">
            {{ rowData?.taskname || "--" }}
          </template>
          <template #createtime="{ rowData: rowData }">
            {{
              rowData?.createtime
                ? $iApi.moment(rowData?.createtime, "YYYY-MM-DD HH:mm:ss")
                : "--"
            }}
          </template>
          <template #taskstatus="{ rowData: rowData }">
            <div :class="`status-${rowData?.status}`">
              <span style="font-weight: bold">·</span>
              {{ statusMap.get(rowData?.status) }}
            </div>
          </template>
          <template #operate="{ rowData: rowData }">
            <el-button type="text" size="small" @click="onPass(rowData)"
              >详情</el-button
            >
          </template>
        </ETable>
      </div>
    </div>
  </div>
</template>

<script>
// 方案审核状态0待审核1通过（进行中）2审核驳回3停用
import {
  taskLists, // 任务列表
  approveTask, // 任务审核
} from "@/api/workbenche";
const statusMap = new Map([
  ["0", "已通过"],
  ["3", "待审核"],
  ["6", "待审核"],
  ["4", "已驳回"],
]);
export default {
  name: "end",
  data() {
    return {
      statusMap: statusMap,
      //表格列表请求体
      listReq: {
        index: 20,
        page: 1,
        data: {
          status: [0, 3, 4, 6],
          taskname: "", // 任务名称
        },
      },
      searchCompany: {},
      //过滤数据
      list: {
        stateList: [
          { label: "停用", value: 0 },
          { label: "启用", value: 1 },
          // {label:'待审核',value:2}
        ],
      },
      tableData: {
        serial: 0,
        operate: 0,
        currentPage: 0,
        data: [],
        cols: [
          { label: "公司名称", prop: "companyname" },
          { label: "任务名称", prop: "taskname" },
          { label: "提交时间", prop: "createtime" },
          { label: "状态", prop: "taskstatus" },
          { label: "操作", prop: "operate" },
        ],
      },
    };
  },
  created() {
    this.$ELoading();
    this.getList().then(() => {
      this.$EClose();
    });
  },
  methods: {
    //获取列表
    getList() {
      return new Promise((resolve) => {
        // this.searchCompany = this.$parent.searchForm.selectedCompany;
        taskLists(this.$req(this.listReq)).then((res) => {
          if (res.code) {
            resolve();
            if (res.code == 1000) {
              this.tableData.data = res.data;
              // console.log("this.tableData", this.tableData);
            } else {
              this.$message.error(res.message ? res.message : "操作失败");
            }
          }
        });
      });
    },
    //详情
    descVip(item) {
      this.readonlyFlag = true;
      this.alterVip(item, "descVip");
    },
    //搜索
    searchEndBtn() {
      this.$ELoading();
      this.getList().then(() => {
        this.$EClose();
      });
    },
    onPass(item) {
      console.log("item", item);
      this.$sessionSet(item);
      this.$router.push({ path: "/Worktable/taskManage/detail" });
    },
    handleSizeChange(val) {
      this.listReq.index = val;
      this.listReq.page = 1;
      this.$iApi.paging(this.getList).then(() => {
        this.$EClose();
      });
    },
    handleCurrentChange(val) {
      this.listReq.page = val;
      this.$iApi.paging(this.getList).then(() => {
        this.$EClose();
      });
    },
  },
};
</script>

<style scoped>
.pageMain {
  width: 100%;
  height: 100%;
  padding: 16px 19px 0 18px;
  box-sizing: border-box;
}

.search_form {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  flex: 1;
}

.fromLabels {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

::v-deep .fromLabels .el-form-item {
  margin-bottom: 0;
}

::v-deep .el-input__inner {
  height: 31px;
  line-height: 31px;
}

::v-deep .el-select .el-input .el-select__caret {
  line-height: 31px;
}

::v-deep .el-select .el-input .el-select__caret {
  color: rgba(100, 102, 107, 1);
}

.label {
  position: relative;
  margin-right: 10px;
}

.btnLabel {
  width: 200px;
  height: 35px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 36px;
}

.btnLabel > button:hover {
  cursor: pointer;
}

.btnLabel > button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.btnLabel img {
  width: 13px;
}

.btnLabel > button:nth-of-type(1) {
  width: 86px;
  height: 35px;
  opacity: 1;
  border-radius: 7px;
  background: rgba(73, 119, 252, 1);
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(73, 119, 252, 1);
}

.btnLabel > button:nth-of-type(2) {
  width: 86px;
  height: 35px;
  opacity: 1;
  border-radius: 7px;
  background: linear-gradient(
    90deg,
    rgba(173, 211, 255, 0.67) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid rgba(73, 119, 252, 1);
  font-size: 12px;
  font-weight: 400;
  color: rgba(73, 119, 252, 1);
}

.tableMain {
  width: 100%;
  height: 100%;
  background: #f5f6fa;
  box-sizing: border-box;
}

.tableBox {
  width: 100%;
  height: 100%;
  background: white;
}

::v-deep .el-table tr {
  background: white;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

::v-deep .el-table .sort-caret.ascending {
  border-bottom-color: #409eff;
  top: 5px;
}

::v-deep .el-table .sort-caret.descending {
  border-top-color: #409eff;
  bottom: 7px;
}

::v-deep .el-table .ascending .sort-caret.ascending {
  border-bottom-color: #c0c4cc;
}

::v-deep .el-table .descending .sort-caret.descending {
  border-top-color: #c0c4cc;
}

/*分页*/
.paging {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

::v-deep .paging .el-input__inner {
  padding: 0 15px !important;
}

::v-deep .scroll .el-table .el-table__cell {
  padding: 4px 0 !important;
}

/*表格最后一列小白块处理*/
::v-deep .el-table th.el-table__cell {
  background: rgb(245, 246, 250);
}
.status-0 {
  color: #43cf7c;
  width: 70px;
  height: 28px;
  line-height: 28px;
  border-radius: 5px;
  text-align: center;
  background-color: rgba(67, 207, 124, 0.2);
}

.status-3,
.status-6 {
  width: 70px;
  height: 28px;
  line-height: 28px;
  border-radius: 5px;
  color: #ff8d1a;
  text-align: center;
  background-color: rgba(255, 141, 26, 0.2);
}

.status-4 {
  color: #ff5733;
  width: 70px;
  height: 28px;
  line-height: 28px;
  border-radius: 5px;
  text-align: center;
  background-color: rgba(255, 87, 51, 0.2);
}
</style>
